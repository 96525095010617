<template>
  <div class="order-create__section">



    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_shipping'])"></div>
      <TitleHorizontal
          :value="$t('ordersInbound_shipping.localization_value.value')"
          :rightBtn="!detail"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 1)"
      />
    </div>
<!--    <div class="order-create__row custom-row">-->
<!--      <div class="order-create__col custom-col">-->
<!--        <RadioLabelPicture-->
<!--                v-if="ordersInbound.data.deliveryServicesId"-->
<!--            class="w-150 mb-2"-->
<!--            :label="_.find(ordersInbound.data.deliveryServices, {id: ordersInbound.data.deliveryServicesId})['name']"-->
<!--            name="shippingCompany"-->
<!--            :disabled="true"-->
<!--            :value="true"-->
<!--        >-->
<!--          <template slot="ico" v-if="_.find(ordersInbound.data.deliveryServices, {id: ordersInbound.data.deliveryServicesId})['logo_base64']">-->
<!--            <img :src="_.find(ordersInbound.data.deliveryServices, {id: ordersInbound.data.deliveryServicesId})['logo_base64']" alt="ico">-->
<!--          </template>-->
<!--        </RadioLabelPicture>-->
<!--      </div>-->
<!--    </div>-->


    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_shipmentDetails', ])"></div>
      {{$t('ordersInbound_shipmentDetails.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_shipmentDate', ])"></div>
                {{$t('ordersInbound_shipmentDate.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.shippingDate"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_receivedAt'])"></div>
                {{$t('ordersInbound_receivedAt.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="ordersInbound.data.receivedAt"
                        :date="true"
                        :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="detail">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_status'])"></div>
                {{$t('ordersInbound_status.localization_value.value')}}
              </div>
              <div class="block-table__content"
                   v-if="status = _.find(ordersInbound.data.optionsStatus, {id: ordersInbound.data.status.id})">
                {{status ? $t(`${status.translation}.localization_value.value`) : '—'}}
              </div>
            </div>

            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_trackingNumber', ])"></div>
              <div class="block-table__label">
                {{$t('ordersInbound_trackingNumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.trackingNumber"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="condition && ordersInbound.data.conditionSelected"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_condition', ])"></div>
              <div class="block-table__label">
                {{$t('ordersInbound_condition.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.conditionSelected.name"
                />
              </div>
            </div>


            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="detail"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_orderProcessPrice', ])"></div>
              <div class="block-table__label">
                {{ $t('ordersInbound_orderProcessPrice.localization_value.value') }}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.orderProcessPrice"
                    :deep="'cost'"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="detail"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_shippingPrice', ])"></div>
              <div class="block-table__label">
                {{ $t('ordersInbound_shippingPrice.localization_value.value') }}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="ordersInbound.data.shippingPrice"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="detail"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_packagingPrice', ])"></div>
              <div class="block-table__label">
                {{ $t('ordersInbound_packagingPrice.localization_value.value') }}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="ordersInbound.data.packagingPrice"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="detail"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_totalOrderProcessPrice', ])"></div>
              <div class="block-table__label">
                {{ $t('ordersInbound_totalOrderProcessPrice.localization_value.value') }}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="ordersInbound.data.totalOrderProcessPrice"
                />
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>

    <div class="custom-row" v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value">
      <div class="custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['ordersInbound_dimensions'])"></div>
          {{ $t('ordersInbound_dimensions.localization_value.value') }}
        </div>
        <div class="custom-row">
          <div class="order-create__col custom-col">
            <div class="order-create__table block-table">
              <div class="block-table__row">

                <template v-if="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType">
                  <div class="block-table__col block-table__col--fit">
                    <div class="block-table__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['common_weightLb'])"></div>
                      {{$t('common_weightLb.localization_value.value')}}
                    </div>
                    <div class="block-table__content">
                      <ValueHelper
                              :value="ordersInbound.data.Dimensions.getDimensionsWeightLB()"
                      />
                    </div>
                  </div>
                  <div class="block-table__col block-table__col--fit">
                    <div class="block-table__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['common_weightOz'])"></div>
                      {{$t('common_weightOz.localization_value.value')}}
                    </div>
                    <div class="block-table__content">
                      <ValueHelper
                              :value="ordersInbound.data.Dimensions.getDimensionsWeightOZ()"
                      />
                    </div>
                  </div>
                </template>

                <div class="block-table__col block-table__col--fit">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_heightCm'])"></div>
                    {{$t('common_heightCm.localization_value.value')}}
                  </div>
                  <div class="block-table__content">
                    <ValueHelper
                            :value="ordersInbound.data.Dimensions.getDimensionsHeight()"
                    />
                  </div>
                </div>
                <div class="block-table__col block-table__col--fit">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_lengthCm'])"></div>
                    {{$t('common_lengthCm.localization_value.value')}}
                  </div>
                  <div class="block-table__content">
                    <ValueHelper
                            :value="ordersInbound.data.Dimensions.getDimensionsLength()"
                    />
                  </div>
                </div>
                <div class="block-table__col block-table__col--fit">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
                    {{ $t('common_widthCm.localization_value.value') }}
                  </div>
                  <div class="block-table__content">
                    <ValueHelper
                            :value="ordersInbound.data.Dimensions.getDimensionsWidth()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <template v-if="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
        {{$t('poshta_PersonalInformation.localization_value.value')}}
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <div class="order-create__table block-table">
            <div class="block-table__row">
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_ContactName',])"></div>
                  {{$t('common_ContactName.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Personal.information.personalName"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                  {{$t('common_phone.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Personal.information.personalPhone"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_CompanyName',])"></div>
                  {{$t('ordersInbound_CompanyName.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Personal.information.personalCompany"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress',])"></div>
        {{ $t('poshta_SenderAddress.localization_value.value') }}
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <div class="order-create__table block-table">
            <div class="block-table__row">
              <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
                  {{$t('common_address.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.address"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_addressSecond',])"></div>
                  {{$t('common_addressSecond.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.address2"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
                  {{$t('common_city.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.city"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_region',])"></div>
                  {{$t('common_region.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.region"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_country',])"></div>
                  {{$t('common_country.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.country.label"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                  {{$t('common_zipCode.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  <ValueHelper
                          :value="ordersInbound.data.Delivery.delivery.zip"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--<div class="custom-row">-->
      <!--<div class="custom-col">-->
        <!--<div class="order-create__section-label section-label"-->
             <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
          <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_settings'])"></div>-->
          <!--{{ $t('ordersInbound_settings.localization_value.value') }}-->
        <!--</div>-->
        <!--<div class="custom-row">-->
          <!--<div class="order-create__col custom-col">-->
            <!--<div class="order-create__table block-table">-->
              <!--<div class="block-table__row">-->
                <!---->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->

    <template v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||
              $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value">
      <div class="order-create__section-label section-label noPrintable"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_documents', ])"></div>
        {{$t('ordersInbound_documents.localization_value.value')}}
      </div>

      <div class="custom-row mb-3 noPrintable">
        <div class="order-create__col custom-col"
             v-if="ordersInbound.data.Documents.data.files.length > 0"
        >
          <ValueHelper
              :value="_.first(ordersInbound.data.Documents.getFiles()).file.name"
          />
        </div>

        <div class="custom-col block-table__content"
             v-else-if="Object.keys(ordersInbound.data.Documents.data.downloadFiles).length > 0"
        >
          <DownloadFileBlock
              v-for="(item, index) in ordersInbound.data.Documents.data.downloadFiles"
              :key="index"
              :file="item"
              :optionDelete="false"
          />
        </div>

        <div class="order-create__col custom-col"
             v-else
        >
          <ValueHelper/>
        </div>
      </div>

    </template>

    <template>
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_comments'])"></div>
        {{ $t('ordersInbound_comments.localization_value.value') }}
      </div>

      <div class="custom-row mb-3">
        <div class="custom-col custom-col--50 custom-col--md-100 mb-3 block-table__content word-break">
          <ValueHelper
              :value="ordersInbound.data.comment"
          />
        </div>
      </div>

      <div class="order-create__row custom-row"
           v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||
              $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"
      >
        <div class="order-create__col custom-col"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['ordersInbound_checkAndRepack'])"></div>
          <DefaultCheckbox
                  class="wfc"
                  :label="$t('ordersInbound_checkAndRepack.localization_value.value')"
                  :value="ordersInbound.data.checkAndRepack"
                  :disabled="true"
                  @change.native="ordersInbound.data.checkAndRepack = !ordersInbound.data.checkAndRepack"
          />
        </div>
      </div>

    </template>

    <div class="custom-row " v-if="ordersInbound.data.Order.data.warehouse">
      <div class="custom-col">
        <RadioDefault
            class="white-space-line width-initial mr-3"
            :label="ordersInbound.data.Order.data.warehouse.name"
            :name="'warehouse'"
            :value="true"
            :disabled="true"
        />
      </div>
    </div>


    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_products'])"></div>
      <TitleHorizontal
          :value="$t('ordersInbound_products.localization_value.value')"
          :rightBtn="!detail"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 2)"
      />
    </div>

    <div class="custom-row " v-if="isAdmin && detail">
      <div class="custom-col">

        <div class="site-table-wrap  "
             v-if="!isMobileFunc()"
        >
          <table class="site-table">
            <thead>
            <tr>
              <th class="max-40">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
              <th class="max-200">{{ $t('fbm_ProductName.localization_value.value') }}</th>
              <th >{{ $t('fba_Cells.localization_value.value') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in ordersInbound.data.productsForCellArray"
                :key="index"
            >
              <td class="max-20" v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                {{item.data.productCount}}
              </td>
              <td class="max-200">
                <div class="table-row ellipsis">
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </div>
              </td>
              <td>
                <template v-if="cells = getCellsInTable(item)">
                  <div v-for="(item, index) in cells.text" :key="index">
                    {{item}}
                  </div>
                </template>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in ordersInbound.data.productsForCellArray"
                 :key="index">
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('productForCell_scannedCount.localization_value.value')}}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{item.data.productCount}}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                          :value="item.data.ProformProduct"
                          :deep="'product.productObject.name_for_fba'"
                      />
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fba_Cells.localization_value.value') }}
                    <span>
                      <template v-if="cells = getCellsInTable(item)">
                        <div v-for="(item, index) in cells.text" :key="index">
                          {{item}}
                        </div>
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="custom-row" v-else>
      <template
          v-for="(item, index) in allProductsArray">
        <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
             v-if="item.product.productName"
             :key="index"
        >
          <div class="confirm-product-card">
            <div class="confirm-product-card__name"
                 v-if="item.product.productObject.name"
            >
              {{ item.product.productObject.name }}
            </div>
            <div class="confirm-product-card__name"
                 v-else
            >
              {{ item.product.productName }}
            </div>

            <div class="confirm-product-card__items">
              <div class="confirm-product-card__item" v-if="item.product.proformData.proformCategory !== ''">

                <div class="confirm-product-card__item-name"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                  {{$t('proform_category.localization_value.value')}}
                </div>
                <div class="confirm-product-card__item-val"
                     v-if="item.product.proformData.proformCategory"
                >
                  {{item.product.proformData.proformCategory.translationStorage.en.name}}
                </div>
              </div>

              <template v-if="localProformTypes = item.product.proformData.proformTypes">
                <template  v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                  <div class="confirm-product-card__item" :key="index" v-if="isUkrainian">
                    <div class="confirm-product-card__item-name">
                      {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name }}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : ''}}
                    </div>
                  </div>
                  <div class="confirm-product-card__item" :key="index + '2'">
                    <div class="confirm-product-card__item-name">
                      {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name }}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <div class="confirm-product-card__bottom" v-if="item.product.handMade"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
              <StatusIcoBtn :type="'handmade'" class="mr-2"/>
              {{$t('product_Handmade.localization_value.value')}}
            </div>

            <div class="confirm-product-card__bottom"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_ItemsQty',
                        ])"></div>
              <div class="confirm-product-card__item" :key="index">
                <div class="confirm-product-card__item-name">
                  {{$t('common_ItemsQty.localization_value.value')}}
                </div>
                <div class="confirm-product-card__item-val">
                  {{item.product.itemsQuantity}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

<!--    <div class="block-table__total site-line-total"-->
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate([-->
<!--							'common_TotallyPerOrder',-->
<!--						])"></div>-->
<!--      {{$t('common_TotallyPerOrder.localization_value.value')}}-->
<!--      <span>$3</span>-->
<!--    </div>-->

  </div>

</template>

<script>
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  // import RadioLabelPicture from "../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
  import DownloadFileBlock from "../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
  import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
  name: "StepFinal",
  components: {
    RadioDefault,
    DefaultCheckbox,
    ValueHelper,
    TitleHorizontal,
    // RadioLabelPicture,
    StatusIcoBtn,
    DownloadFileBlock,
  },

  mixins: [mixinDetictingMobile],

  props: {
    ordersInbound: Object,
    user: Object,
    condition: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    countProformProducts: function() {
      if(this.detail) {
        this.initAllProductArray()
      }
    },
  },

  computed: {
    countProformProducts(){
      return this.ordersInbound.data.ProformProducts.length
    },

    // getProductsArray() {
    //   if(this.detail){
    //     return this.ordersInbound.data.ProformProducts
    //   } else {
    //     return
    //   }
    // },
  },

  data(){
    return{
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      allProductsArray: [],
    }
  },

  mounted() {
    this.initAllProductArray()
  },

  methods: {
    initAllProductArray(){
      this.allProductsArray = []
      this.ordersInbound.data.ProformProducts.map((item) => {
        this.allProductsArray.push(item)
      })

      this.ordersInbound.data.AddedProducts.map((item) => {
        this.allProductsArray.push(item)
      })
    },

    getCellsInTable(item) {
      let text = []
      let count = -1

      let UserProductCellArray = item.data.UserProductCellArray,
          UserMixedProductCellArray = item.data.UserMixedProductCellArray

      let UserCellArray = item.data.UserCellArray
      let FreeCellArray = item.data.FreeCellArray
      let MixedCellArray = item.data.MixedCellArray

      let comboCellArraysArray = [
        UserProductCellArray,
        UserMixedProductCellArray,
        UserCellArray,
        FreeCellArray,
        MixedCellArray
      ]

      comboCellArraysArray.forEach(cellArray => {
        cellArray.map(cellItem => {
          if (cellItem.data.cellCount > 0) {
            let cellItemSelected = cellItem.data.cellSelected
            text.push(cellItemSelected?.combo_name)
            count ++
          }
        })
      })

      return {text, count}
    },

  }
}
</script>

<style media="print">

.print-btn-head{
  max-width: 167px;
}

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .max-200{
    max-width: 200px !important;
  }

  .max-40{
    max-width: 40px !important;
    width: 40px !important;
  }

  .header__inner{
    display: none !important;
  }

  .default-layout__page-content{
    margin-top: 30px;
  }

  .site-table-wrap.table-small,
  .site-table {
    width: initial !important;
    max-width: 500px;
  }

  .search-engine-block__inner{
    display: none !important;
  }

}
</style>