<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_settings'])"></div>
        {{ $t('ordersInbound_settings.localization_value.value') }}
      </div>
    </template>

    <template slot="body">
      <div class="fragment pt-4">
        <AdminFieldsBlock
          :ordersInbound="ordersInbound"
          :user="ordersInbound.data.User"
        />
      </div>
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
import {ORDER_INBOUND_TYPE} from "../../../../../../../../staticData/staticVariables";
import AdminFieldsBlock from "../../../../components/chunks/AdminFieldsBlock/AdminFieldsBlock";

export default {
  name: "Setting",
  components: {
    AccordBlock,
    AdminFieldsBlock,
  },

  mixins: [

  ],

  props: {
    ordersInbound: Object,
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">

</style>
