<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getOrdersInbound.length > 0 && !$store.getters.getOrdersInboundLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
           'user-table-scope' : !isAdmin}">
        <div class="admin-edit" v-if="isAdmin" @click="editTranslate([
          'ordersInbound_order',
          'ordersInbound_created',
          'common_user',
          'ordersInbound_method',
          'ordersInbound_shipped',
          'ordersInbound_received',
          'ordersInbound_comment',
          'ordersInbound_condition',
          'ordersInbound_trackingNumber',
          'ordersInbound_qTy',
          'common_manage',
          'common_edit',
          'common_EditDraft',
          'common_Marking',
          'common_delete',
          'common_termalPrint',

          'ordersInbound_fbmOrder',
          'ordersInbound_fbaOrder',
          'ordersInbound_returnLabel',
        ])"></div>

        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('ordersInbound_order.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_created.localization_value.value') }}</th>
            <th v-if="isAdmin">{{ $t('common_user.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_method.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_shipped.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_received.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_comment.localization_value.value') }}</th>
            <th v-if="showCondition">{{ $t('ordersInbound_condition.localization_value.value') }}</th>
            <th>{{ $t('ordersInbound_trackingNumber.localization_value.value') }}</th>
            <th class="text-lg-right white-space-line">{{ $t('ordersInbound_qTy.localization_value.value') }}</th>
            <th width="100" class="hide-1600"></th>
            <th>{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-bind:class="{
                'site-table__draft-row': item['status_id'] === ORDER_INBOUND_STATUS.STATUS_DRAFT,
              }"
              v-for="(item, index) in $store.getters.getOrdersInbound"
              :key="index"
          >
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <router-link
                  :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
                  class="d-flex justify-content-center">
                <TooltipTextHelper>
                  <template slot="text">
                    <div>
                      <LabelCircle
                          v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBM.value && !item['return']"
                          :color="'blue'" :value="'S'" class="mr-8"/>
                      <LabelCircle
                          v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBA.value && !item['return']"
                          :color="'gray'" :value="'A'" class="mr-8"/>
                      <LabelCircle v-if="item['return']"
                                   :color="'red'" :value="'R'" class="mr-8"/>
                    </div>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBM.value && !item['return']">
                        <LabelCircle :color="'blue'" :value="'S'" class="mr-8"/>
                        {{ $t('ordersInbound_fbmOrder.localization_value.value') }}
                      </template>
                      <template v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBA.value && !item['return']">
                        <LabelCircle :color="'gray'" :value="'A'" class="mr-8"/>
                        {{ $t('ordersInbound_fbaOrder.localization_value.value') }}
                      </template>
                      <template v-if="item['return']">
                        <LabelCircle :color="'red'" :value="'R'" class="mr-8"/>
                        {{ $t('ordersInbound_returnLabel.localization_value.value') }}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>
<!--                <v-popover class="site-tooltip" offset="5" placement="top-left" trigger="hover">-->
<!--                  <div>-->
<!--                    <LabelCircle-->
<!--                        v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBM.value && !item['return']"-->
<!--                        :color="'blue'" :value="'S'" class="mr-8"/>-->
<!--                    <LabelCircle-->
<!--                        v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBA.value && !item['return']"-->
<!--                        :color="'gray'" :value="'A'" class="mr-8"/>-->
<!--                    <LabelCircle v-if="item['return']"-->
<!--                                 :color="'red'" :value="'R'" class="mr-8"/>-->
<!--                  </div>-->
<!--                  <template slot="popover">-->
<!--                    <div class="status-tooltip">-->
<!--                      <template v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBM.value && !item['return']">-->
<!--                        <LabelCircle :color="'blue'" :value="'S'" class="mr-8"/>-->
<!--                        {{ $t('ordersInbound_fbmOrder.localization_value.value') }}-->
<!--                      </template>-->
<!--                      <template v-if="item['fulfillment'] === ORDER_INBOUND_TYPE.FBA.value && !item['return']">-->
<!--                        <LabelCircle :color="'gray'" :value="'A'" class="mr-8"/>-->
<!--                        {{ $t('ordersInbound_fbaOrder.localization_value.value') }}-->
<!--                      </template>-->
<!--                      <template v-if="item['return']">-->
<!--                        <LabelCircle :color="'red'" :value="'R'" class="mr-8"/>-->
<!--                        {{ $t('ordersInbound_returnLabel.localization_value.value') }}-->
<!--                      </template>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-popover>-->
                <span class="table-link btn-style">#{{ item.id }}</span>
              </router-link>
            </td>
            <td>
              <ValueHelper
                  :value="item['created_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <div class="table-row">
							<span class="table-ico">
                <template v-if="item['delivery_service'] && item['delivery_service']['logo_base64']">
                  			<img :src="item['delivery_service']['logo_base64']" alt="ico">
                </template>
                <ValueHelper v-else
                             :value="item['delivery_service']"
                             :deep="'name'"
                />
							</span>
              </div>
            </td>
            <td>
              <ValueHelper
                  :value="item['shipped_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item['received_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <div class="table-row">
                <TooltipTextHelper
                    class="comment-row"
                    :text="item['comment']"
                    :fullTextWidth="130"
                    :paragraph="item['comment']"
                    :styleClass="'white-space-normal style-ellipsis'"
                />
              </div>
            </td>
            <td v-if="showCondition">
              <ValueHelper
                  :value="item['product_condition']"
                  :deep="'name'"
              />
            </td>
            <td class="white-space-line">

              <div class="d-flex align-items-center"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit"
                     @click="editTranslate(['ordersInbound_trackingNumber', 'ordersInbound_addNumber'])"></div>
                <template v-if="item.tracking_number">
                  <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
                  >
                    <div class="table-link btn-style ellipsis">
                      {{ item.tracking_number }}
                    </div>
                    <template slot="popover">
                      <p>{{ item.tracking_number }}</p>
                    </template>
                  </v-popover>
                  <TooltipTextHelperPlugin :key="item.tracking_number" :fullTextWidth="300" :trigger="'click'" v-if="!item.return_label_entity">
                    <template slot="text">
                        <span class="site-link">
                            <LinkButton
                                class="ml-1"
                                :type="'edit'"/>
                        </span>
                    </template>
                    <template slot="paragraph">
                      <div class="section-label mb-2">{{
                          $t('ordersInbound_trackingNumber.localization_value.value')
                        }}
                      </div>
                      <InputEditField
                          :value="item.tracking_number"
                          :propId="item.id"
                          :hideEditField="hideEditField"
                          @onChange="(data) => changeTrackingNumber(data, item)"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </TooltipTextHelperPlugin>
                </template>
                <template v-else>
                  <TooltipTextHelperPlugin :key="item.tracking_number" :fullTextWidth="300" :trigger="'click'">
                    <template slot="text">
                      <span class="table-link btn-style">{{
                          $t('ordersInbound_addNumber.localization_value.value')
                        }}</span>
                    </template>
                    <template slot="paragraph">
                      <div class="section-label mb-2">{{
                          $t('ordersInbound_trackingNumber.localization_value.value')
                        }}
                      </div>
                      <InputEditField
                          :value="''"
                          :propId="item.id"
                          :hideEditField="hideEditField"
                          @onChange="(data) => changeTrackingNumber(data, item)"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </TooltipTextHelperPlugin>
                </template>
              </div>

              <!--<div class="table-row">-->
              <!--<div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
              <!--<span class="admin-edit"-->
              <!--@click="editTranslate(['ordersInbound_trackingNumber', 'ordersInbound_addNumber'])"></span>-->
              <!--<template v-if="item['tracking_number']">-->
              <!--<span class="table-link btn-style">{{item['tracking_number']}}</span>-->
              <!--<div class="table-ico">-->
              <!--<v-popover-->
              <!--class="site-tooltip"-->
              <!--offset="5"-->
              <!--placement="top-left"-->
              <!--@show="hideEditField = true"-->
              <!--@hide="hideEditField = false"-->
              <!--&gt;-->
              <!--<span class="site-link">-->
              <!--<LinkButton-->
              <!--:type="'edit'"-->
              <!--/>-->
              <!--</span>-->
              <!--<template slot="popover">-->
              <!--<div class="section-label mb-2">{{ $t('ordersInbound_trackingNumber.localization_value.value') }}-->
              <!--</div>-->
              <!--<InputEditField-->
              <!--:value="item['tracking_number']"-->
              <!--:propId="item['id']"-->
              <!--:hideEditField="hideEditField"-->
              <!--:disabledBtn="$store.getters.getOrdersInboundBtn"-->
              <!--@onChange="changeTrackNumber"-->
              <!--/>-->
              <!--<a v-close-popover ref="closePopover" class="tooltip__close"></a>-->
              <!--</template>-->
              <!--</v-popover>-->
              <!--</div>-->
              <!--</template>-->

              <!--<ValueHelper v-else/>-->
              <!--</div>-->
              <!--</div>-->
            </td>
            <td align="right">
              <ValueHelper
                  :value="item['item_total_quantity']"
              />
            </td>
            <td class="hide-1600"></td>
            <td>
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">

                  <template slot="item" v-if="isStatusDraft(item)">
                    <LinkButton
                        :value="$t('common_EditDraft.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                        getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
                    />

                  </template>
                  <template slot="item" v-if="isAdmin || (!isStatusDraft(item) && !isStatusComplete(item) && !item.return_label_entity)">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                        getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
                    />
                  </template>

                  <template slot="item" v-if="!isStatusDraft(item)">
                    <LinkButton
                        :value="$t('common_Marking.localization_value.value')"
                        :type="'mark'"
                        @click.native="$emit('getInboundOrderMarking', {id: item.id})"
                    />
                  </template>
                  <template slot="item" v-if="(!isStatusComplete(item) && !item.return_label_entity) || (isAdmin && !isStatusComplete(item))">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeItem(item.id)"
                    />
                  </template>
                  <template slot="item" v-if="!isStatusDraft(item)">
                    <LinkButton
                        :value="$t('common_termalPrint.localization_value.value')"
                        :type="'print'"
                        @click.native="$emit('thermalPrint', {id: item.id})"
                    />
                  </template>
                  <template slot="item" v-if="item.return_label_entity">
                    <LinkButton
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + item.return_label_entity.uuid"
                        :value="$t('common_GetLabel.localization_value.value')"
                        :type="'dog'"
                        :target="'_blank'"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">

            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersInbound"
                 :key="index"
                 v-bind:class="{
                  'draft-row': item['status_id'] === ORDER_INBOUND_STATUS.STATUS_DRAFT,
                }"
            >
              <OrdersInboundTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  @removeItem="removeItem(item.id)"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
                  @getInboundOrderMarking="(data) => $emit('getInboundOrderMarking', data)"
                  @changeTrackNumber="data => changeTrackingNumber(data, item)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getOrdersInboundCommonList.next_page_url !== null && $store.getters.getOrdersInbound.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getOrdersInboundCommonList.total - $store.getters.getOrdersInboundForPage * $store.getters.getOrdersInboundCommonList.current_page < $store.getters.getOrdersInboundForPage ?
                  $store.getters.getOrdersInboundCommonList.total - $store.getters.getOrdersInboundForPage * $store.getters.getOrdersInboundCommonList.current_page:
                  $store.getters.getOrdersInboundForPage"
          />
          <ExportBtn
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getOrdersInboundLoading === false && $store.getters.getOrdersInbound.length === 0">
      <NoResult
          class="pt-4"
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
import LabelCircle from "../../../../../../coreComponents/LabelCircle/LabelCircle";
import {
  ORDER_INBOUND_NAV,
  ORDER_INBOUND_STATUS,
  ORDER_INBOUND_TYPE
} from "../../../../../../../staticData/staticVariables";
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import {
  ordersInboundTableMixin
} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import OrdersInboundTableMobile from "./OrdersInboundTableMobile/OrdersInboundTableMobile";
import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";


export default {
  name: "OrdersInboundTable",

  components: {
    TooltipTextHelperPlugin,
    TableUserColumn,
    TooltipTextHelper,
    ValueHelper,
    ShowMore,
    ExportBtn,
    DefaultCheckbox,
    LinkButton,
    ManagerButton,
    InputEditField,
    LabelCircle,
    NoResult,
    OrdersInboundTableMobile,
  },


  mixins: [mixinDetictingMobile, ordersInboundTableMixin],

  props: {
    selectedNow: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  computed: {
    showCondition() {
      if (this._.has(this.navTabs, ORDER_INBOUND_NAV.FBA.name)) {
        return !this.navTabs[ORDER_INBOUND_NAV.FBM.name].active && !this.navTabs[ORDER_INBOUND_NAV.FBA.name].active
      }
      return !this.navTabs[ORDER_INBOUND_NAV.FBM.name].active //&& !this.navTabs[ORDER_INBOUND_NAV.FBA.name].active
    }
  },

  data() {
    return {
      orderId: '',
      showFilter: false,

      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      ORDER_INBOUND_STATUS: ORDER_INBOUND_STATUS,
      ORDER_INBOUND_NAV: ORDER_INBOUND_NAV,
      hideEditField: false,
    }
  },


  methods: {
    changeTrackingNumber(data, item) {
      this.$store.dispatch('updateInboundTrackingNumber',
          {
            id: data.id,
            data: {
              'tracking_number': data.value
            }
          }).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            let data = this.getRespData(response)
            item.tracking_number = data?.tracking_number
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    }
  },

}
</script>

<style lang="scss" scoped>
.site-table thead th,
.site-table tbody td {
  padding-right: 5px;
  padding-left: 5px;
}


.table-manage-list .manager-btn {
  width: 300px;
  @media (max-width: 1600px) {
    width: 200px;
  }
}

.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
  padding-left: 10px;
}

.site-table thead th:nth-child(2),
.site-table tbody td:nth-child(2) {
  padding-left: 0;
}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.site-table .ellipsis {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.user-table-scope .hide-1600 {
  width: 100%;
}

.user-table-scope td:last-child {
  padding-right: 25px !important;
}

@media(max-width: 1600px) {
  .site-table .ellipsis.tacking-number {
    max-width: 60px;
  }
  .hide-1600 {
    display: none;
  }
}


</style>
