<template>
  <div class="fragment">
    <div class="custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_status'])"></div>
        <DefaultSelect
            :label="$t('ordersInbound_status.localization_value.value')"
            :options="ordersInbound.data.optionsStatus"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': ordersInbound.validation.status}"
            :errorTxt="$t(`${ordersInbound.validationTranslate.status}.localization_value.value`)"
            :error="ordersInbound.validation.status"
            :selected="ordersInbound.data.status"
            @change="(val) => ordersInbound.setStatus(val)"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_receivedAt', ])"></div>
        <DatePickerDefault
            :label="$t('ordersInbound_receivedAt.localization_value.value')"
            :value="ordersInbound.data.receivedAt"
            v-bind:class="{'ui-no-valid': ordersInbound.validation.receivedAt}"
            :errorTxt="$t(`${ordersInbound.validationTranslate.receivedAt}.localization_value.value`)"
            :error="ordersInbound.validation.receivedAt"
        >
          <template slot="body">
            <date-picker
                v-model="ordersInbound.data.receivedAt"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :readonly="true"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>

    <div class="custom-row">
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-50"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_orderProcessPrice', ])"></div>-->
        <!--<DefaultSelect-->
            <!--:options="ordersInbound.data.orderProcessPriceList"-->
            <!--:label="$t('ordersInbound_orderProcessPrice.localization_value.value')"-->
            <!--:optionsLabel="'cost'"-->
            <!--v-bind:class="{'ui-no-valid': ordersInbound.validation.orderProcessPrice}"-->
            <!--:errorTxt="$t(`${ordersInbound.validationTranslate.orderProcessPrice}.localization_value.value`)"-->
            <!--:error="ordersInbound.validation.orderProcessPrice"-->
            <!--:selected="ordersInbound.data.orderProcessPrice"-->
            <!--@change="val => {ordersInbound.setOrderProcessPrice(val)}"-->
        <!--/>-->
      <!--</div>-->


      <!--<div class="order-create__col custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-25 custom-col&#45;&#45;sm-50"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_orderProcessPrice', ])"></div>-->
        <!--<InputSum-->
                <!--:label="$t('ordersInbound_orderProcessPrice.localization_value.value')"-->
                <!--v-bind:class="{'ui-no-valid': ordersInbound.validation.orderProcessPrice}"-->
                <!--:errorTxt="$t(`${ordersInbound.validationTranslate.orderProcessPrice}.localization_value.value`)"-->
                <!--:error="ordersInbound.validation.orderProcessPrice"-->
                <!--v-model="ordersInbound.data.orderProcessPrice"-->
                <!--@change="(val) => ordersInbound.setOrderProcessPrice(val)"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-25 custom-col&#45;&#45;sm-50"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_packagingPrice', ])"></div>-->
        <!--<InputSum-->
            <!--:label="$t('ordersInbound_packagingPrice.localization_value.value')"-->
            <!--:icoType="'dollar'"-->
            <!--:numeric="true"-->
            <!--v-bind:class="{'ui-no-valid': ordersInbound.validation.packagingPrice}"-->
            <!--:errorTxt="$t(`${ordersInbound.validationTranslate.packagingPrice}.localization_value.value`)"-->
            <!--:error="ordersInbound.validation.packagingPrice"-->
            <!--v-model="ordersInbound.data.packagingPrice"-->
            <!--@change="(val) => ordersInbound.setPackagingPrice(val)"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-25 custom-col&#45;&#45;sm-50"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_shippingPrice', ])"></div>-->
        <!--<InputSum-->
            <!--:label="$t('ordersInbound_shippingPrice.localization_value.value')"-->
            <!--:icoType="'dollar'"-->
            <!--:numeric="true"-->
            <!--v-bind:class="{'ui-no-valid': ordersInbound.validation.shippingPrice}"-->
            <!--:errorTxt="$t(`${ordersInbound.validationTranslate.shippingPrice}.localization_value.value`)"-->
            <!--:error="ordersInbound.validation.shippingPrice"-->
            <!--v-model="ordersInbound.data.shippingPrice"-->
            <!--@change="(val) => ordersInbound.setShippingPrice(val)"-->
        <!--/>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from "vue2-datepicker";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "AdminFieldsBlock",
  components: {
    DatePickerDefault,
    DatePicker,
    DefaultSelect,
  },

  props: {
    ordersInbound: Object,
  },

  data(){
    return {

    }
  },
}
</script>

<style scoped>

</style>
