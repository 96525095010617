
<template>
	<div class="edit-field-input">
		<div class="edit-field-input__inner">
			<label :for="'edit-field-input-' + id" class="edit-field-input__label-wrap"
						 :class="{ active: activeInput || value.length > 0 }">
				<input
						class="edit-field-input__input"
						:id="'edit-field-input-' + id"
						:type="type"
						:value="content"
						:placeholder="placeholder"
						v-on:input="handleInput($event.target.value)"
						@focus="activeInput = true"
						@blur="activeInput = false"
						@keyup.enter="change"
				>
				<span class="edit-field-input__label"
							v-if="label"
				>
          {{ label }}
        </span>
			</label>
			<button class="edit-field-input__btn"
							v-bind:class="{'disable-edit-field-btn': disabledBtn}"
							@click="change"></button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "InputEditField",

		data () {
			return {
				id: null,
				content: this.value,
				activeInput: false,
			}
		},

		props: [
			'type',
			'label',
			'placeholder',
			'value',
			'propId',
			'hideEditField',
			'disabledBtn'
		],

		watch: {
			value(newVal) {
				this.value = newVal
			},
			propId(newVal) {
				this.propId = newVal
			},
			hideEditField() {
				this.content = this.value
			},
		},

		mounted () {
			this.id = this._uid
		},

		methods: {
			handleInput (value) {
				this.content = value
				this.$emit('input', value)
			},

			change() {
				this.$emit('onChange', {value: this.content, id: this.propId})
			},
		}
	}
</script>

<style lang="scss">
	@import "../../../../scss/mixins/mixins";
	@import "../../../../scss/colors";

	.disable-edit-field-btn{
		filter: grayscale(1);
		pointer-events: none;
	}

	.edit-field-input{
		position: relative;
		z-index: 2;

		&__inner{
			display: flex;
		}

		&__label-wrap{
			display: block;
			position: relative;
			width: 100%;

			&.active input{
				border-color: $accent;
			}

			&.active .edit-field-input__label{
				top: -5px;
				background: white;
				background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
				padding: 0 2px;
				font-size: 10px;
				line-height: 12px;
			}
		}


		&.white &__input{
			background: white;
		}


		&__input{
			font-size: 14px;
			line-height: 16px;
			width: 100%;
			padding-left: 16px;
			height: 42px;
			background: $white;
			border: 1px solid $borderBrown;
			border-right: 0;
			border-radius: 5px 0px 0px 5px;
			background: $mainBg;
			font-weight: 500;
			color: $black;

			@include for-992{
				font-size: 16px!important;
			}

			&::placeholder{
				font-size: 14px;
				line-height: 16px;
				color: $borderBrown;
				line-height: revert;
			}
		}

		&.active input{
			border-color: $accent;
		}

		&__label{
			position: absolute;
			left: 16px;
			top: 13px;
			font-size: 14px;
			line-height: 16px;
			color: $borderBrown;
			transition: 0.15s all;
			cursor: auto;
		}

		&__btn{
			background: $accent url("../../../../assets/img/UI-group/arrow-white-rigth.svg") center center no-repeat;
			border-radius: 0px 5px 5px 0px;
			min-width: 42px;
			height: 42px;
			border: 0;
			outline: 0;
			cursor: pointer;
		}

	}

</style>

