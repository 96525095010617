<template>
  <div class="fragment">
    <ProductsBlock
        class="mb-5"
        :ordersInbound="ordersInbound"
        :user="user"
        :products="ordersInbound.data.ProformProducts"
        :disabledWarehouse="disabledWarehouse"
    />

    <CreateProductsBlock
        :ordersInbound="ordersInbound"
        :user="user"
        :products="ordersInbound.data.AddedProducts"
    />
  </div>
</template>

<script>

import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
import {ordersInboundMixin} from "../../../../../../mixins/ordersInbound/ordersInboundMixin";
import ProductsBlock from "../chunks/ProductsBlock/ProductsBlock";
import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
import CreateProductsBlock from "../chunks/CreateProductsBlock/CreateProductsBlock";

export default {
  name: "StepB",
  components: {
    CreateProductsBlock,
    ProductsBlock,
  },

  mixins:[
    ordersInboundMixin,
    proformMixins
  ],

  props:{
    ordersInbound: Object,
    user: Object,
    disabledWarehouse: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      ordersInboundType: ORDER_INBOUND_TYPE,
      serverError: false,
    }
  },

  mounted() {

  },
}
</script>

<style scoped>

</style>