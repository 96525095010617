import { render, staticRenderFns } from "./OrdersInboundTable.vue?vue&type=template&id=5deca6a3&scoped=true"
import script from "./OrdersInboundTable.vue?vue&type=script&lang=js"
export * from "./OrdersInboundTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5deca6a3",
  null
  
)

export default component.exports