<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['ordersInbound_order'])"></span>
        <DefaultInput
            :label="$t('ordersInbound_order.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['ordersInbound_trackingNumber'])"></span>
        <DefaultInput
            :label="$t('ordersInbound_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['ordersInbound_created'])"></span>
        <DatePickerDefault
            :label="$t('ordersInbound_created.localization_value.value')"
            v-model="createdAt"
        >
          <template slot="body">
            <date-picker
                v-model="createdAt"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
                range
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['ordersInbound_shipped'])"></span>
        <DatePickerDefault
            :label="$t('ordersInbound_shipped.localization_value.value')"
            v-model="shippedAt"
        >
          <template slot="body">
            <date-picker
                v-model="shippedAt"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
                range
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['ordersInbound_received'])"></span>
        <DatePickerDefault
            :label="$t('ordersInbound_received.localization_value.value')"
            v-model="receivedAt"
        >
          <template slot="body">
            <date-picker
                v-model="receivedAt"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
                range
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "OrdersInboundFilter",
    components: {
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        // fulfillment: this.filterGetParams.fulfillment ? this.filterGetParams.fulfillment : '',
        // return: this.filterGetParams.return ? this.filterGetParams.return : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        createdAt: this.filterGetParams.createdAt ? this.generateFilterDate(this.filterGetParams.createdAt, 'MM/DD/YY') : '',
        shippedAt: this.filterGetParams.shippedAt ? this.generateFilterDate(this.filterGetParams.shippedAt, 'MM/DD/YY') : '',
        receivedAt: this.filterGetParams.receivedAt ? this.generateFilterDate(this.filterGetParams.receivedAt, 'MM/DD/YY') : '',


        filterCounts: [
          'id',
          'userName',
          'userId',
          // 'fulfillment',
          // 'return',
          'trackingNumber',
          'createdAt',
          'shippedAt',
          'receivedAt',
        ],

        options: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
        // this.fulfillment = newVal.fulfillment ? newVal.fulfillment : ''
        // this.return = newVal.return ? newVal.return : ''
        this.id = newVal.id ? newVal.id : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.createdAt = newVal.createdAt ? this.generateFilterDate(newVal.createdAt, 'MM/DD/YY') : ''
        this.shippedAt = newVal.shippedAt ? this.generateFilterDate(newVal.shippedAt, 'MM/DD/YY') : ''
        this.receivedAt = newVal.receivedAt ? this.generateFilterDate(newVal.receivedAt, 'MM/DD/YY') : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdAt = this.changeDateParams(this, 'createdAt')
        data.shippedAt = this.changeDateParams(this, 'shippedAt')
        data.receivedAt = this.changeDateParams(this, 'receivedAt')

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
