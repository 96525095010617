<template>
  <OrdersInboundTableContent
      :navTabs="navTabs"
      @changeTab="changeTab"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @downloadFiles="downloadFiles"
      @thermalPrint="thermalPrint"
      @getInboundOrderMarking="getInboundOrderMarking"
  />
</template>

<script>
  import OrdersInboundTableContent from "./OrdersInboundTableContent/OrdersInboundTableContent";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {ORDER_INBOUND_NAV} from "../../../../../staticData/staticVariables";
  import {ordersInboundMixin} from "../../../../../mixins/ordersInbound/ordersInboundMixin";

  export default {
    name: "OrdersInboundTable",
    components: {
      OrdersInboundTableContent,
    },

    mixins: [routeFilter, ordersInboundMixin],

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      },

      checkLoadOrders(newVal) {
        if(this.$route.query.openLabel){
          let order = this._.find(newVal, {id: parseInt(this.$route.query.openLabel)})
          let query = Object.assign({}, this.$route.query);
          delete query.openLabel;
          this.$router.replace({ query });
          this.openLabelInbound(order.return_label_entity.uuid)
        }
      },
    },

    computed: {
      checkLoadInbounds() {
        return this.$store.getters.getOrdersInbound
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getOrdersFBAForPage,
        page: 1,
        onePage: 1,
        filterId: '',
        filterType: '',
        filterUserName: '',
        filterUserId: '',
        // filterId: '',

        filterFulfillment: '',
        filterReturn: '',
        filterTrackingNumber: '',
        filterCreatedAt: '',
        filterShippedAt: '',
        filterReceivedAt: '',

        countFilterParams: 0,
        filterGetParams: {},


        navTabs: {
          all: {
            active: true,
            label: 'ordersInbound_allShipments',
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          [ORDER_INBOUND_NAV.FBM.name]: {
            active: false,
            label: 'ordersInbound_FBM',
            name: ORDER_INBOUND_NAV.FBM.name,
            id: ORDER_INBOUND_NAV.FBM.id,
            permissionVisible: false,
          },
          // [ORDER_INBOUND_NAV.FBA.name]: {
          //   active: false,
          //   label: 'ordersInbound_FBA',
          //   name: ORDER_INBOUND_NAV.FBA.name,
          //   id: ORDER_INBOUND_NAV.FBA.id,
          //   permissionVisible: false,
          // },
          [ORDER_INBOUND_NAV.RETURNS.name]: {
            active: false,
            label: 'ordersInbound_Returns',
            name: ORDER_INBOUND_NAV.RETURNS.name,
            id: ORDER_INBOUND_NAV.RETURNS.id,
            permissionVisible: false,
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.downloadPermissions){
        this.checkTabPermission()
      }

    },

    methods: {

      openLabelInbound(uuid) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.getPdfFile + '/' + uuid
        })

        window.open(routeData.href, '_blank');
      },

      checkTabPermission() {
        this.activeTabByPermissions('navTabs', {
          [ORDER_INBOUND_NAV.FBM.name]: this.PERMISSIONS.ORDER_INBOUND_FBM,
          // [ORDER_INBOUND_NAV.FBA.name]: this.PERMISSIONS.ORDER_INBOUND_FBA,
          [ORDER_INBOUND_NAV.RETURNS.name]: this.PERMISSIONS.ORDER_INBOUND_RETURNS,
        })

        this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          id: this.$route.query.id,
          fulfillment: this.$route.query.fulfillment,
          return: this.$route.query.return,
          trackingNumber: this.$route.query.trackingNumber,
          createdAt: this.$route.query.createdAt,
          shippedAt: this.$route.query.shippedAt,
          receivedAt: this.$route.query.receivedAt,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersInboundPage', true)
        this.$store.dispatch('fetchOrdersInbound', {filter: url}).then(() => {
          this.$store.commit('setNextOrdersInboundPage', false)
        })


        this.checkCountFilter(['type', 'userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterUserId: 'user_id',
              filterFulfillment : 'fulfillment',
              filterReturn : 'return',
              filterTrackingNumber : 'tracking_number',
            },
        )

        let fulfilmentField = '',
            returnField = ''

        /**
         * Проверяю на фулфилмент и или это возврат, чтобы применить фильтры
         */

        switch (this.filterType) {
          case ORDER_INBOUND_NAV.FBM.name:
            // myQuery.where('fulfillment', 'fbm')
            // myQuery.where('return', false)
            fulfilmentField = 'fbm'
            returnField = false
            break
          case ORDER_INBOUND_NAV.FBA.name:
            // myQuery.where('fulfillment', 'fba')
            // myQuery.where('return', false)
            fulfilmentField = 'fba'
            returnField = false
            break
          case ORDER_INBOUND_NAV.RETURNS.name:
            // myQuery.where('return', true)
            returnField = true
            break
        }

        if (fulfilmentField !== '')
          myQuery.where('fulfillment', fulfilmentField)

        if (returnField !== '')
          myQuery.where('return', returnField)


        if (this.filterCreatedAt.length > 0) {
          let createdAtDate = this.generateDateForFilter(this.filterCreatedAt, 'YYYY-MM-DD')
          myQuery.whereIn(
              'createdAt',
              createdAtDate
          )
        }

        if (this.filterShippedAt.length > 0) {
          let shippedAtDate = this.generateDateForFilter(this.filterShippedAt, 'YYYY-MM-DD')
          myQuery.whereIn(
              'shippedAt',
              shippedAtDate
          )
        }
        if (this.filterReceivedAt.length > 0) {
          let receivedAtDate = this.generateDateForFilter(this.filterReceivedAt, 'YYYY-MM-DD')
          myQuery.whereIn(
              'receivedAt',
              receivedAtDate
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      thermalPrint(data) {

        if (!data.id) return

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/inbound-order-thermal-print',
          query: {
            id: data.id,
            type: 'pdf'
          }
        })

        window.open(routeData.href, '_blank');

      },

      getInboundOrderMarking(data) {
        let type = 'docx'
        this.$store.dispatch('getInboundOrderFileBlob', {id: data.id, type: type}).then((response) => {
          this.downloadBlobFile(response, type)
        })
      },
    },
  }
</script>

<style scoped>

</style>
