<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
            class="empty-label"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />
        <router-link
                :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
            class="table-card__item-number">
          #{{item.id}}
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          <ValueHelper
              :value="item['created_at']"
              :date="true"
              :dateType="'DD MMM, YYYY'"
          />
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['created_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="item"
                  :mobile="true"
              />
            </div>
          </div>

<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('fba_inboundID.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <ValueHelper-->
<!--                  :value="item['inboundShipment']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('ordersInbound_method.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
             <span class="table-ico">
                <template v-if="item['delivery_service'] && item['delivery_service']['logo_base64']">
                  			<img :src="item['delivery_service']['logo_base64']" alt="ico">
                </template>
                <ValueHelper v-else
                             :value="item"
                             :deep="'delivery_service.name'"
                />
							</span>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('ordersInbound_shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['shipped_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('ordersInbound_received.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['received_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('ordersInbound_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['comment']"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('ordersInbound_condition.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['product_condition']"
                  :deep="'name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('ordersInbound_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-row">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['ordersInbound_trackingNumber', 'ordersInbound_addNumber'])"></span>
                  <template v-if="item['tracking_number']">
                    <div class="d-flex align-items-center">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top"
                          trigger="hover"
                      >
                      <span class="table-link btn-style ellipsis">
                        {{item.tracking_number}}
                      </span>
                        <template slot="popover">
                          <p>{{ item.tracking_number }}</p>
                        </template>
                      </v-popover>
                      <div class="table-ico">
                        <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                        >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                          <template slot="popover">
                            <div class="section-label mb-2">{{ $t('ordersInbound_trackingNumber.localization_value.value') }}
                            </div>
                            <InputEditField
                                :value="item['tracking_number']"
                                :propId="item['id']"
                                :hideEditField="hideEditField"
                                :disabledBtn="$store.getters.getOrdersInboundBtn"
                                @onChange="data => $emit('changeTrackNumber', data)"
                            />
                            <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                          </template>
                        </v-popover>
                      </div>
                    </div>
                  </template>

                  <ValueHelper v-else/>
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('ordersInbound_qTy.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item['item_total_quantity']"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i" v-if="isAdmin || (!isStatusDraft(item) && !isStatusComplete(item) && !item.return_label_entity)">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                        getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="isStatusDraft(item)">
            <LinkButton
                :value="$t('common_EditDraft.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                        getOrderType(item['fulfillment'], item['return']).value + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="(!isStatusComplete(item) && !item.return_label_entity) || (isAdmin && !isStatusComplete(item))">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="!isStatusDraft(item)">
            <LinkButton
                    :value="$t('common_Marking.localization_value.value')"
                    :type="'mark'"
                    @click.native="$emit('getInboundOrderMarking', {id: item.id})"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="!isStatusDraft(item)">
            <LinkButton
                    :value="$t('common_termalPrint.localization_value.value')"
                    :type="'print'"
                    @click.native="$emit('thermalPrint', {id: item.id})"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="item.return_label_entity">
            <LinkButton
                :link="$store.getters.GET_PATHS.getPdfFile + '/' + item.return_label_entity.uuid"
                :value="$t('common_GetLabel.localization_value.value')"
                :type="'dog'"
                :target="'_blank'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>

import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import {FBA_ORDER_STATUS, ORDER_INBOUND_TYPE} from "../../../../../../../../staticData/staticVariables";
import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
import {ordersInboundTableMixin} from "../../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "OrdersInboundTableMobile",

  components: {
    LinkButton,
    TableUserColumn,
    ValueHelper,
    InputEditField,
    DefaultCheckbox,
  },

  mixins: [ordersInboundTableMixin],

  props: {
    item: Object,
    shopId: String,
    selectedNow: Boolean,
  },

  data() {
    return {
      show: false,
      FBA_ORDER_STATUS: FBA_ORDER_STATUS,
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      hideEditField: false,
    }
  }

}
</script>

<style scoped>
.table-card__item-body .ellipsis {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
