<template>
  <div class="fragment">
    <div class="detail-page">
      <div class="detail-page__left noPrintable">
        <CardLeftBlock
                :backgroundImage="'shipments'"
                :value="`#${ordersInbound.data.Order.getId()}`"
        >
          <template slot="body">
            <div class="card-detail-left__date">
              <ValueHelper
                      :value="ordersInbound.getCreatedAt() | moment('DD MMM, YYYY')"
              />
              <div class="site-link brown text-decoration-underline mt-2">
                <div v-if="isAdmin && ordersInbound.data.Order.data.historyOfChanged"
                     class="mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
                  <div class="site-link text-decoration-underline btn-style brown"
                       @click="historyOfChangesFunc('inbound', ordersInbound.data.Order.data.historyOfChanged)">
                    {{$t('common_CheckHistory.localization_value.value')}}
                  </div>
                </div>
              </div>

              <div class="custom-row noPrintable" v-if="isAdmin">
                <div class="custom-col">
                  <MainButton
                      :value="'Print'"
                      @click.native="printTable"
                  />
                </div>
              </div>

            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head mb-3 noPrintable">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_backTo', ])"></div>
                  <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse" class="order-create__head-back">
                    <LinkBack
                            :value="$t('ordersInbound_backTo.localization_value.value')"
                    />
                  </router-link>
                </div>
              </div>

              <template
                      v-if="$route.params.type === ORDER_INBOUND_TYPE.FBM.value"
              >
                <div class="order-create__head-title color-lightBlue"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_detailShipment', ])"></div>
                  {{$t('ordersInbound_detailShipment.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.id === ORDER_INBOUND_TYPE.FBA.value"
              >
                <div class="order-create__head-title color-gray"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_detailShipment', ])"></div>
                  {{$t('ordersInbound_detailShipment.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.id === ORDER_INBOUND_TYPE.FBAReturn.value"
              >
                <div class="order-create__head-title color-red"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_detailShipment', ])"></div>
                  {{$t('ordersInbound_detailShipment.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.id === ORDER_INBOUND_TYPE.FBMReturn.value"
              >
                <div class="order-create__head-title color-red"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_detailShipment', ])"></div>
                  {{$t('ordersInbound_detailShipment.localization_value.value')}}
                </div>
              </template>

            </div>
          </template>

          <template slot="body">
            <StepFinal
                    :ordersInbound="ordersInbound"
                    :proformProducts="ordersInbound.data.ProformProducts"
                    :user="ordersInbound.data.User"
                    :condition="true"
                    :detail="true"
            />

          </template>
          <template slot="footer">
            <div class="order-create__footer noPrintable"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel'])"></div>
              <div class="order-create__footer-link">
                <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse"
                             class="site-link site-link--alt"
                >
                  {{ $t('common_cancel.localization_value.value') }}
                </router-link>
              </div>
            </div>
          </template>
        </CardRightBlock>
      </div>
    </div>
  </div>
</template>

<script>
  import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import StepFinal from "../../components/StepFinal/StepFinal";
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "OrdersInboundDetailUser",
    components: {
      MainButton,
      ValueHelper,
      StepFinal,
      CardLeftBlock,
      LinkBack,
      CardRightBlock,
    },

    props: {
      ordersInbound: Object,
    },

    data() {
      return {
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      }
    },

    mounted() {
      console.log(this.$route.params.id);
    },

    methods: {

      printTable() {
        window.print()
      }
    }

  }
</script>

<style scoped>

  @media (min-width: 993px) {
    .progress-step-shipments {
      max-width: 520px;
    }
  }
</style>
