<template>
  <div class="user-card">


<!--    <div class="user-card__img">-->
<!--      <div class="user-card__img-i">-->
<!--        <img v-if="user.userAvatar.id"-->
<!--             :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`" alt="img">-->
<!--        &lt;!&ndash;<img v-else src="/img/trash-group/user-photo.jpg" alt="img">&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
    <div class="user-card__content mt-0">
      <div class="user-card__head">
        <div class="user-card__head-info">
          <div class="user-card__head-row">
            <div class="block-table">
              <div>
                <v-popover class="site-tooltip" offset="5" placement="top-left" trigger="hover">
                  <div>
                    <LabelCircle
                        v-if="$route.params.type === ORDER_INBOUND_TYPE.FBM.value"
                        :color="'blue'" :value="'S'" class="mr-8"/>
                    <LabelCircle
                        v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value"
                        :color="'red'" :value="'R'" class="mr-8"/>
                  </div>
                  <template slot="popover">
                    <div class="status-tooltip">
                      <template
                          v-if="$route.params.type === ORDER_INBOUND_TYPE.FBM.value">
                        <LabelCircle :color="'blue'" :value="'S'" class="mr-8"/>
                        {{ $t('ordersInbound_fbmOrder.localization_value.value') }}
                      </template>
                      <template
                          v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value">
                        <LabelCircle :color="'red'" :value="'R'" class="mr-8"/>
                        {{ $t('ordersInbound_returnLabel.localization_value.value') }}
                      </template>
                    </div>
                  </template>
                </v-popover>
              </div>
              <div class="block-table__row">
                <div class="block-table__col block-table__col--50">
                  <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${ordersInbound.data.User.user.id}`">
                    <div class="user-card__head-name mb-2" v-if="Object.keys(ordersInbound.data.User.user).length > 0">
                      <ValueHelper
                          :value="ordersInbound.data.User.user"
                          :deep="'user_personal_contact.user_full_name'"
                      />
                    </div>
                  </router-link>
                  <div class="amount-row light"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_Balance'])"></div>
                    {{ $t('common_Balance.localization_value.value') }}
                    <!--              Balance:-->
                    <span class="color-red important font-weight-bold m-0">
                      $ {{ ordersInbound.data.User.user.balance }}
                    </span>
                  </div>
                </div>
                <div class="block-table__col block-table__col--50 noPrintable">

                  <div v-if="!ordersInbound.data.Order.data.isDraft"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_termalPrint'])"></div>
                    <MainButton
                        :value="$t('common_termalPrint.localization_value.value')"
                        @click.native="thermalPrint({id: ordersInbound.data.Order.data.id})"
                    />
                  </div>
                  <div class="mt-2" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <MainButton
                        :value="'Print'"
                        @click.native="printTable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="user-card__head-row">
            <div class="user-info-admin__history mt-2">
              <div v-if="ordersInbound.data.Order.data.historyOfChanged"
                   class="mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
                <div class="site-link text-decoration-underline btn-style brown"
                     @click="historyOfChangesFunc('inbound', ordersInbound.data.Order.data.historyOfChanged)">
                  {{$t('common_CheckHistory.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="user-card__body">


        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_CustomerInformation', 'common_edit'])"></div>
<!--              Customer Information-->
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ordersInbound_CustomerInformation.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changeInformationDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_ContactName'])"></div>
                {{ $t('ordersInbound_ContactName.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="personal.information.personalName"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_CompanyName'])"></div>
                {{ $t('ordersInbound_CompanyName.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="personal.information.personalCompany"
                />
              </div>
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_PhoneNumber'])"></div>
                {{ $t('ordersInbound_PhoneNumber.localization_value.value') }}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="personal.information.personalPhone"
                />
              </div>
            </div>

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_Address', 'common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ordersInbound_Address.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @click.native="changeDeliveryDataPopup()"
              />
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_City'])"></div>
                {{ $t('ordersInbound_City.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.city"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_Region'])"></div>
                {{ $t('ordersInbound_Region.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.region"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_Country'])"></div>
                {{ $t('ordersInbound_Country.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.country"
                    :deep="'label'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_ZipCode'])"></div>
                {{ $t('ordersInbound_ZipCode.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.zip"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_Address'])"></div>
                {{ $t('ordersInbound_Address.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.address"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{ $t('common_addressSecond.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="delivery.delivery.address2"
                />
              </div>
            </div>

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_Price', 'common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ordersInbound_Price.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changeAPIDataPopup"
              />
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_orderProcessPrice'])"></div>
                {{ $t('ordersInbound_orderProcessPrice.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.orderProcessPrice"
                    :deep="'cost'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_shippingPrice'])"></div>
                {{ $t('ordersInbound_shippingPrice.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.shippingPrice"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_packagingPrice'])"></div>
                {{ $t('ordersInbound_packagingPrice.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.packagingPrice"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_totalOrderProcessPrice'])"></div>
                {{ $t('ordersInbound_totalOrderProcessPrice.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ordersInbound.data.totalOrderProcessPrice"
                />
              </div>
            </div>


            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ordersInbound_comments', 'common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ordersInbound_comments.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changeCommentDataPopup"
              />
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
                {{ $t('common_comment.localization_value.value') }}
              </div>

              <div class="block-table__content" style="word-break: break-word">
                <ValueHelper
                    :value="ordersInbound.data.comment"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ordersInbound_checkAndRepack'])"></div>
                {{ $t('ordersInbound_checkAndRepack.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <DefaultCheckbox
                    class="wfc"
                    :label="''"
                    :value="ordersInbound.data.checkAndRepack"
                    @change.native="ordersInbound.data.checkAndRepack = !ordersInbound.data.checkAndRepack"
                />
              </div>
            </div>
          </div>

        </div>
      </div>

      <ChangeDataPopup
          v-if="isModalChangeAddressPopup"
          :items="changeDataPopup"
          @save="saveOrderData"
          @changeSelect="changeSelect"
          @close="isModalChangeAddressPopup = false"
      />

    </div>
  </div>
</template>

<script>
import TitleHorizontal from "../../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import ChangeDataPopup from "../../../../../popups/ChangeDataPopup/ChangeDataPopup";
import {PersonalInformation} from "../../../../../../../globalModels/PersonalInformation";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import {Delivery} from "../../../../../../../globalModels/Delivery";
import {ordersInboundMixin} from "../../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import {OrdersInbound} from "../../../../../models/OrdersInbound";
import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import LabelCircle from "@/components/coreComponents/LabelCircle/LabelCircle";
import {ORDER_INBOUND_TYPE} from "@/staticData/staticVariables";

export default {
  name: "OrderCard",
  components: {
    LabelCircle,
    DefaultCheckbox,
    MainButton,
    ValueHelper,
    TitleHorizontal,
    ChangeDataPopup,
  },

  mixins: [
    ordersInboundMixin
  ],

  props: {
    ordersInbound: Object,
    user: Object,
    personal: Object,
    delivery: Object,
  },

  data() {
    return {
      isModalChangePasswordPopup: false,
      isModalPhonesPopup: false,
      isModalChangeAddressPopup: false,
      isModalHistoryOfChanges: false,

      changeDataPopup: [],

      typeFieldsChanges: null,

      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,

      /**
       * Fake models for check validation on Change Data Popup
       */
      PI: new PersonalInformation(),
      Delivery: new Delivery(),
      OrdersInboundLocal: new OrdersInbound(),
    }
  },

  methods: {

    printTable() {
      window.print()
    },

    changeChangePasswordPopup(val) {
      this.isModalChangePasswordPopup = val
    },

    changePhonesPopup(val) {
      this.isModalPhonesPopup = val
    },

    changeChangeDataPopup(val) {
      this.isModalChangeAddressPopup = true
      this.changeDataPopup = val
    },

    changeHistoryOfChanges(val) {
      this.isModalHistoryOfChanges = val
    },

    changeInformationDataPopup() {
      this.isModalChangeAddressPopup = true

      this.PI.setPersonalName(this.personal.information.personalName)
      this.PI.setPersonalPhone(this.personal.information.personalPhone)
      this.PI.setPersonalCompany(this.personal.information.personalCompany)

      this.setPopupInformationData()
      this.typeFieldsChanges = 'personal'
    },

    changeDeliveryDataPopup() {
      this.isModalChangeAddressPopup = true

      this.Delivery.setAddress(this.delivery.delivery.address)
      this.Delivery.setAddress2(this.delivery.delivery.address2)
      this.Delivery.setCity(this.delivery.delivery.city)
      this.Delivery.setRegion(this.delivery.delivery.region)
      this.Delivery.setCountry(this.delivery.delivery.country)
      this.Delivery.setZip(this.delivery.delivery.zip)

      this.setPopupDeliveryData()
      this.typeFieldsChanges = 'delivery'
    },


    changeAPIDataPopup() {
      this.isModalChangeAddressPopup = true

      this.setPopupAPIData()
      this.typeFieldsChanges = 'api'
    },

    changeCommentDataPopup() {
      this.isModalChangeAddressPopup = true

      this.setPopupCommentData()
      this.typeFieldsChanges = 'comment'
    },

    changeSelect(data) {
      this.APIUpdateOrderPrice(data)
    },

    saveOrderData(data) {
      switch (this.typeFieldsChanges) {
        case 'personal':
          this.personalUpdate(data)
          break

        case 'delivery':
          this.deliveryUpdate(data)
          break

        case 'api':
          this.APIUpdate(data)
          break

        case 'comment':
          this.commentUpdate(data)
          break
      }
    },

    thermalPrint(data) {

      if (!data.id) return

      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.generatePdfFile + '/inbound-order-thermal-print',
        query: {
          id: data.id,
          type: 'pdf'
        }
      })

      window.open(routeData.href, '_blank');

    },

  }
}
</script>

<style media="print">

.print-btn-head{
  max-width: 167px;
}

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none;
  }

  body .onlyPrintable {
    display: block !important;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .max-200{
    max-width: 200px !important;
  }

  .max-40{
    max-width: 40px !important;
    width: 40px !important;
  }

  .header__inner{
    display: none !important;
  }

  .default-layout__page-content{
    margin-top: 30px;
  }

  .site-table-wrap.table-small,
  .site-table {
    /*width: initial !important;*/
  }


  .site-table-wrap.table-small th{
    width: initial !important;
  }

  .site-table-wrap.table-small td:nth-child(2),
  .site-table-wrap.table-small th:nth-child(2){
    width: 20px !important;
    /*background: blue;*/
  }

  .site-table-wrap.table-small td:nth-child(3),
  .site-table-wrap.table-small th:nth-child(3){
    width: 200px !important;
    /*background: red;*/
  }

  .site-table-wrap.table-small td:nth-child(4),
  .site-table-wrap.table-small th:nth-child(4){
    width: 200px !important;
  }

  .order-admin-products .site-table-wrap{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table-wrap.table-small td:first-child,
  .site-table-wrap.table-small td:last-child,
  .site-table-wrap.table-small th:first-child,
  .site-table-wrap.table-small th:last-child{
    display: none;
  }

  .search-engine-block__inner{
    display: none !important;
  }

}
</style>
