<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_AddProductCreate'])"></div>
      {{$t('ordersInbound_AddProductCreate.localization_value.value')}}
      <!-- Add Product (Create New Product) -->
    </div>

    <template v-for="(item, index) in products">

      <div class="order-create__title-horizontal"
           :key="index + '1'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
        <TitleHorizontal
            :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
            :rightBtn="true"
            :rightBtnType="'delete'"
            :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
            @rightBtnClick="removeProformProduct(products, index)"
        />

        <div class="custom-row">
          <div class="custom-col custom-col--33 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
            <DefaultInput
                :label="$t('product_UPCCode.localization_value.value')"
                :errorTxt="serverError ? item.validationTxt.UPC : $t(`${item.validationTranslate.UPC}.localization_value.value`)"
                v-model="item.product.UPC"
                v-bind:class="{'ui-no-valid': item.validation.UPC}"
                :error="item.validation.UPC"
            />
          </div>
          <div class="custom-col custom-col--33 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_SKU',
                ])"></span>
            <DefaultInput
                :label="$t('product_SKU.localization_value.value')"
                :errorTxt="serverError ? item.validationTxt.SKU : $t(`${item.validationTranslate.SKU}.localization_value.value`)"
                v-model="item.product.SKU"
                v-bind:class="{'ui-no-valid': item.validation.SKU}"
                :error="item.validation.SKU"
            />
          </div>
          <div class="custom-col custom-col--33 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
            <DefaultInput
                :label="$t('product_ProductName.localization_value.value')"
                :errorTxt="serverError ? item.validationTxt.productName : $t(`${item.validationTranslate.productName}.localization_value.value`)"
                v-model="item.product.productName"
                v-bind:class="{'ui-no-valid': item.validation.productName}"
                :error="item.validation.productName"
            />
          </div>
        </div>
      </div>

      <ProductItemCreation
          :key="index"
          :product="item"
          :serverError="serverError"
          :altVersion="true"
          @changeCategory="({val, nameVal}) => changeCategoryProform( {val, nameVal, item: products[index]}, ordersInbound.data.Order.getWarehouseCountry(), true)"
          @changeProformSelectItem="changeProformSelectItem(products[index], ordersInbound.data.Order.getWarehouseCountry())"
          :hideAddProfile="true"
      />
    </template>

<!--    <div class="order-create__product-btn add-product-btn-wrap"-->
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>-->
<!--      <span class="order-create__product-btn-i add-product-btn site-link"-->
<!--            @click="addProformProduct(ordersInbound.data.AddedProducts)">-->
<!--          + {{$t('common_AddProduct.localization_value.value')}}-->
<!--        </span>-->
<!--    </div>-->

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('common_AddProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="addProformProduct(products)"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <!--    <div class="order-create__total mb-3"-->
    <!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
    <!--      <div class="admin-edit" @click="editTranslate(['common_TotalShippingCost',])"></div>-->
    <!--      {{$t('common_TotalShippingCost.localization_value.value')}}-->
    <!--      <span>${{calculatePrice}}</span>-->
    <!--    </div>-->

  </div>
</template>

<script>
import {ordersInboundMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
import {ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";
import {proformMixins} from "../../../../../../../mixins/proformMixins/proformMixins";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusAccentIconSVG from "../../../../../../../../public/img/UI-group/plus-accent-icon.svg?inline";
// import SelectProductBlock from "../../../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "CreateProductsBlock",
  components: {
    TitleHorizontal,
    ProductItemCreation,
    // SelectProductBlock,
    DefaultInput,
    MainButton,
    PlusAccentIconSVG,
  },

  mixins:[
    ordersInboundMixin,
    proformMixins,
  ],

  props:{
    ordersInbound: Object,
    user: Object,
    products: Array,
  },

  data() {
    return {
      ordersInboundType: ORDER_INBOUND_TYPE,
      serverError: false,
      optionsProducts: []
    }
  },

  mounted() {

  },

  methods: {

  }

}
</script>

<style scoped>

</style>
