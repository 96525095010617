<template>
  <div class="order-create__row custom-row">

    <template v-if="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType">
      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_weightLb'])"></div>
        <DefaultInput
                :label="$t('common_weightLb.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.weightLB}"
                :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.weightLB}.localization_value.value`)"
                :error="ordersInbound.data.Dimensions.validation.weightLB"
                v-model="ordersInbound.data.Dimensions.data.weightLB"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_weightOz'])"></div>
        <DefaultInput
                :label="$t('common_weightOz.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.weightOZ}"
                :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.weightOZ}.localization_value.value`)"
                :error="ordersInbound.data.Dimensions.validation.weightOZ"
                v-model="ordersInbound.data.Dimensions.data.weightOZ"
        />
      </div>
    </template>

    <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_heightCm'])"></div>
      <DefaultInput
          :label="$t('common_heightCm.localization_value.value')"
          :type="'text'"
          :placeholder="'e.g. 10'"
          v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.height}"
          :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.height}.localization_value.value`)"
          :error="ordersInbound.data.Dimensions.validation.height"
          v-model="ordersInbound.data.Dimensions.data.height"
      />
    </div>
    <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_lengthCm'])"></div>
      <DefaultInput
          :label="$t('common_lengthCm.localization_value.value')"
          :type="'text'"
          :placeholder="'e.g. 10'"
          v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.dimensionLength}"
          :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
          :error="ordersInbound.data.Dimensions.validation.dimensionLength"
          v-model="ordersInbound.data.Dimensions.data.dimensionLength"
      />
    </div>
    <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>

      <DefaultInput
          :label="$t('common_widthCm.localization_value.value')"
          :type="'text'"
          :placeholder="'e.g. 10'"
          v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.width}"
          :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.width}.localization_value.value`)"
          :error="ordersInbound.data.Dimensions.validation.width"
          v-model="ordersInbound.data.Dimensions.data.width"
      />
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {ordersInboundMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";

export default {
  name: "DimensionsBlock",
  components: {
    DefaultInput,
  },

  mixins: [ordersInboundMixin],

  props:{
    ordersInbound: Object,
  },
}
</script>

<style scoped>

</style>
