<template>
  <div class="order-create__section">
<!--    <ShippingCompanyBlock-->
<!--        :ordersInbound="ordersInbound"-->
<!--    />-->

    <ShippingDetailsBlock
            :ordersInbound="ordersInbound"
            :personal="ordersInbound.data.Personal"
            :delivery="ordersInbound.data.Delivery"
    />

  </div>
</template>

<script>
// import ShippingCompanyBlock from "../chunks/ShippingCompanyBlock/ShippingCompanyBlock";
import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
import ShippingDetailsBlock from "../chunks/ShippingDetailsBlock/ShippingDetailsBlock";


export default {
  name: "StepC",
  components: {
    ShippingDetailsBlock,
    // ShippingCompanyBlock,
  },

  props: {
    ordersInbound: Object,
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  mounted() {

  },
}
</script>

<style scoped>

</style>
