<template>
  <div class="fragment order-admin-products">
    <div class="custom-row">
      <div class="custom-col">

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
        >
          <table class="site-table">
            <thead>
            <tr>
              <th style="width: 20px;">
                <DefaultCheckbox
                    v-if="_.has(this.currentPermissions, PERMISSIONS.SELECT_ALL_SCAN_PRODUCTS)"
                    style="width: 17px; min-height: 17px; background: white; padding-left: 1px; padding-top: 1px;"
                    @input="changeCheckedAllProducts"
                />
              </th>
              <th style="width: 100px;">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
              <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('consolidations_nowInCells.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('fba_Cells.localization_value.value') }}</th>
<!--              <th class="noPrintable" style="width: 120px;">{{ $t('consolidations_scanCell.localization_value.value') }}</th>-->
              <th style="width: 120px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in productsForCellArray"
                :key="index"
            >
              <td>
                <ProductCheckedBlock
                        :item="item"
                        @changeProductChecked="(val) => prepareChangeProductChecked(val, item)"
                />
              </td>
              <td v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                {{item.data.scannedCount}} / {{item.data.productCount}}
              </td>
              <td>
                <div class="table-row ellipsis max-200">
                  <ValueHelper
                          :value="item.data.ProformProduct"
                          :deep="'product.productObject.name_for_fba'"
                  />
                </div>
              </td>
              <td>
                <template v-for="(cellItem, cellIndex) in getItemsWithCells(item)">
                  <div :key="cellIndex">
                    {{cellItem.cell.combo_name}} ({{cellItem.quantity}})
                    <template v-if="getItemsWithCells(item).length > 1 && cellIndex < getItemsWithCells(item).length - 1">,</template>
                  </div>
                </template>
              </td>
              <td>
                <div class="noPrintable">
                  <template v-if="cells = getCellsInTable(item)">
                    {{cells.text}}
                    <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                  </template>
                </div>

                <div class="onlyPrintable">
                  <template v-if="cellsName = getCellsNameInTable(item)">
                    <div v-for="(item, index) in cellsName.text" :key="index">
                      {{item}}
                    </div>
                  </template>
                </div>
              </td>
<!--              <td class="noPrintable">-->
<!--                <DefaultInput-->
<!--                    :type="'text'"-->
<!--                    :resetAfter="true"-->
<!--                    @change="(val) => {changeScanner(val, item)}"-->
<!--                />-->
<!--              </td>-->
              <td>
                <div class="manage-block">
                  <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      class="mr-3"
                      @click.native="openProductForCellPopup(index)"
                  />
                  <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                          @click.native="ordersInbound.removeProductForCells(ordersInbound.data.productsForCellArray, index)"
                  />
                </div>

              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in productsForCellArray"
                 :key="index">
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__head">
                  <ProductCheckedBlock
                          :item="item"
                          @changeProductChecked="(val) => prepareChangeProductChecked(val, item)"
                  />
                  <ValueHelper
                          :value="item.data.Product"
                          :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('productForCell_scannedCount.localization_value.value')}}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{item.data.scannedCount}} / {{item.data.productCount}}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                              :value="item.data.ProformProduct"
                              :deep="'product.productObject.name_for_fba'"
                      />
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('consolidations_nowInCells.localization_value.value') }}
                    <span>
                      <template v-for="(cellItem, cellIndex) in getItemsWithCells(item)">
                        <div :key="cellIndex">
                          {{cellItem.cell.combo_name}} ({{cellItem.quantity}})
                          <template v-if="getItemsWithCells(item).length > 1 && cellIndex < getItemsWithCells(item).length - 1">,</template>
                        </div>
                      </template>
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fba_Cells.localization_value.value') }}
                    <span>
                      <template v-if="cells = getCellsInTable(item)">
                        {{cells.text}}
                        <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                      </template>
                    </span>
                  </div>
                </div>
<!--                <div class="site-table-mobile__row custom-row noPrintable">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{ $t('consolidations_scanCell.localization_value.value') }}-->
<!--                    <DefaultInput-->
<!--                        :type="'text'"-->
<!--                        style="width: 150px;"-->
<!--                        :resetAfter="true"-->
<!--                        @change="(val) => {changeScanner(val, item)}"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col d-flex align-items-center">
                    {{ $t('common_manage.localization_value.value') }}
                    <div class="ml-2 d-flex">
                      <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              class="mr-3"
                              @click.native="openProductForCellPopup(index)"
                      />
                      <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="ordersInbound.removeProductForCells(ordersInbound.data.productsForCellArray, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__row custom-row noPrintable">
      <div class="order-create__col custom-col mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_AddAnExistingProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('ordersInbound_AddAnExistingProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openProductPopup"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
      <div class="order-create__col custom-col"
           v-if="!isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_AddNewProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('ordersInbound_AddNewProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openAddProductPopup"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

<!--    <pre style="font-size: 9px">-->
<!--      {{ ordersInbound.data.productsForCellArray }}-->
<!--    </pre>-->

    <ProductsPopup
      v-if="isModalProductsPopup"
      :ordersInbound="ordersInbound"
      :user="user"
      :productDefault="ordersInbound.data.productDefault"
      @addProduct="addProduct"
      @close="changeProductsPopup(false)"
    />

    <AddNewProductPopup
            v-if="isModalAddProductPopup"
            :ordersInbound="ordersInbound"
            :user="user"
            @close="changeAddProductPopup(false)"
    />

    <ProductForCellPopup
        v-if="isModalProductForCellPopup"
        :orderType="'inbound'"
        :user="user"
        :productForCellItem="ordersInbound.data.productsForCellArray[arrayIndex]"
        :productsForCellArray="ordersInbound.data.productsForCellArray"
        :arrayIndex="arrayIndex"
        @close="changeProductForCellPopup(false)"
    />
  </div>
</template>

<script>
// import SelectProductBlock from "../../../../../../coreComponents/SelectProductBlock/SelectProductBlock";
import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
import PlusAccentIconSVG from "../../../../../../../../../public/img/UI-group/plus-accent-icon.svg?inline";
import ProductsPopup from "../../../../../popups/ProductsPopup/ProductsPopup";
import ProductForCellPopup from "../../../../../../../coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import {ordersInboundMixin} from "../../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import ProductCheckedBlock from "../ProductCheckedBlock/ProductCheckedBlock";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import AddNewProductPopup from "../../../../../popups/AddNewProductPopup/AddNewProductPopup";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import _ from "lodash";
// import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "ProductsAdminBlock",
  components: {
    // DefaultInput,
    DefaultCheckbox,
    AddNewProductPopup,
    ValueHelper,
    ProductCheckedBlock,
    // SelectProductBlock,
    LinkButton,
    MainButton,
    PlusAccentIconSVG,
    ProductsPopup,
    ProductForCellPopup,
  },

  mixins: [
    ordersInboundMixin, mixinDetictingMobile
  ],

  props: {
    ordersInbound: Object,
    user: Object,
    proformProducts: Array,
    productsForCellArray: Array,
    addedProducts: Array,
  },

  data(){
    return{
      isModalProductsPopup: false,
      isModalAddProductPopup: false,
      isModalProductForCellPopup: false,

      arrayIndex: -1,
    }
  },

  methods: {

    changeCheckedAllProducts(val) {
      this.productsForCellArray.map(item => {
        this.prepareChangeProductChecked(val, item, false)

        this.changeInboundProductInCells()
      })
    },

    changeProductsPopup(val){
      this.isModalProductsPopup = val
    },
    changeAddProductPopup(val){
      this.isModalAddProductPopup = val
    },
    changeProductForCellPopup(val){
      this.isModalProductForCellPopup = val
      if(!val) {
        this.recalculateTotalOrderPrice()
        this.changeInboundProductInCells()
      }
    },

    openProductForCellPopup(index = false){
      if(index >= 0){
        this.arrayIndex = index
      } else {
        this.arrayIndex = -1
      }
      this.changeProductForCellPopup(true)
    },

    openAddProductPopup(){
      this.changeAddProductPopup(true)
    },

    openProductPopup(){
      this.changeProductsPopup(true)
    },

    prepareChangeProductChecked(checked, item, updateProductInCells = true) {
      this.changeProductChecked(checked, item)

      if(updateProductInCells) {
        this.changeInboundProductInCells()
      }
    },

    changeProductChecked(checked, item) {
      // let firstProductsForCell = this._.first(item.data.UserProductCellArray)
      // let firstProductsMixedForCell = this._.first(item.data.UserMixedProductCellArray)
      // let firstUserCellArray = this._.find(item.data.UserCellArray, {data: {cellSelected: {mixed: false}}}) || this._.first(item.data.UserCellArray)
      //let firstFreeCell = this._.first(item.data.FreeCellArray)
      // let firstMixedForCell = this._.first(item.data.MixedCellArray)

      if(checked){
        item.allScannedProduct()

        // if(firstProductsForCell){
        //   item.data.withoutCellEndCounts = 0
        //   return firstProductsForCell.data.cellCount = item.data.productCount
        // }
        //
        // if(firstProductsMixedForCell){
        //   item.data.withoutCellEndCounts = 0
        //   return firstProductsMixedForCell.data.cellCount = item.data.productCount
        // }
        //
        // if(firstUserCellArray){
        //   item.data.withoutCellEndCounts = 0
        //   return firstUserCellArray.data.cellCount = item.data.productCount
        // }

        let foundProductsForCell = _.find(item.data.UserProductCellArray, function (item) {
          return Number(item.data.cellSelected?.row_name) > 20
        })

        if(foundProductsForCell) {
          foundProductsForCell.data.cellCount = item.data.productCount
          return true
        }

        return item.data.withoutCellEndCounts = item.data.productCount
        // if(parseInt(item.data.withoutCellDeclaredCounts) !== 0) {
        //   return item.data.withoutCellEndCounts = item.data.productCount
        // }

        // if (firstFreeCell) {
        //   return firstFreeCell.data.cellCount = item.data.productCount
        // }
        //
        // if (firstMixedForCell) {
        //   return firstMixedForCell.data.cellCount = item.data.productCount
        // }
        //
        // return item.data.withoutCellEndCounts = item.data.productCount

      } else {
        item.removeAllScannedProduct()
        if(item.data.UserProductCellArray?.length > 0){
          item.data.UserProductCellArray.forEach(userProductCellItem => {
            userProductCellItem.data.cellCount = 0
          })
        }
        if (item.data.UserMixedProductCellArray?.length > 0) {
          item.data.UserMixedProductCellArray.forEach(userMixedProductCellItem => {
            userMixedProductCellItem.data.cellCount = 0
          })
        }
        // if(firstUserCellArray){
        //   firstUserCellArray.data.cellCount = 0
        // }
        if(item.data.UserCellArray?.length > 0){
          item.data.UserCellArray.forEach(userCellItem => {
            userCellItem.data.cellCount = 0
          })
        }
        if(item.data.FreeCellArray?.length > 0){
          item.data.FreeCellArray.forEach(freeCellItem => {
            freeCellItem.data.cellCount = 0
          })
        }
        if(item.data.MixedCellArray?.length > 0){
          item.data.MixedCellArray.forEach(mixedCellItem => {
            mixedCellItem.data.cellCount = 0
          })
        }
        if(parseInt(item.data.withoutCellEndCounts) !== 0) {
          item.data.withoutCellEndCounts = 0
        }
      }
    },

    getCellsInTable(item) {
      let text = ''
      let count = -1

      let UserProductCellArray = item.data.UserProductCellArray,
        UserMixedProductCellArray = item.data.UserMixedProductCellArray

      let UserCellArray = item.data.UserCellArray
      let FreeCellArray = item.data.FreeCellArray
      let MixedCellArray = item.data.MixedCellArray

      let comboCellArraysArray = [
        UserProductCellArray,
        UserMixedProductCellArray,
        UserCellArray,
        FreeCellArray,
        MixedCellArray
      ]

      comboCellArraysArray.forEach(cellArray => {
        cellArray.map(cellItem => {
          if (cellItem.data.cellCount > 0) {
            let cellItemSelected = cellItem.data.cellSelected
            count === -1 ? text = cellItemSelected?.combo_name : null
            count ++
          }
        })
      })

      // UserProductCellArray.map(userCell => {
      //   let userCellSelected = userCell.data.cellSelected
      //   count === -1 ? text = userCellSelected?.combo_name : null
      //   count ++
      // })
      // UserMixedProductCellArray.map(mixedCell => {
      //   let mixedCellSelected = mixedCell.data.cellSelected
      //   count === -1 ? text = mixedCellSelected?.combo_name : null
      //   count ++
      // })
      return {text, count}
    },

    getCellsNameInTable(item) {
      let text = []
      let count = -1

      let UserProductCellArray = item.data.UserProductCellArray,
          UserMixedProductCellArray = item.data.UserMixedProductCellArray

      let UserCellArray = item.data.UserCellArray
      let FreeCellArray = item.data.FreeCellArray
      let MixedCellArray = item.data.MixedCellArray

      let comboCellArraysArray = [
        UserProductCellArray,
        UserMixedProductCellArray,
        UserCellArray,
        FreeCellArray,
        MixedCellArray
      ]

      comboCellArraysArray.forEach(cellArray => {
        cellArray.map(cellItem => {
          if (cellItem.data.cellCount > 0) {
            let cellItemSelected = cellItem.data.cellSelected
            text.push(cellItemSelected?.combo_name)
            count ++
          }
        })
      })

      return {text, count}
    },

    addProduct(product){
      console.log(product);
    },

    changeScanner(val, item){

      if (!(val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0)) {
        return
      }

      let cellId = val.split('-')[1]
      cellId = parseInt(cellId)

      let
          userProductCellArray = item.data.UserProductCellArray,
          userMixedProductCellArray = item.data.UserMixedProductCellArray,
          userCellArray = item.data.UserCellArray,
          mixedCellArray = item.data.MixedCellArray,
          freeCellArray = item.data.FreeCellArray,
          foundFlag = false,
          foundItemId = false

      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userProductCellArray = userProductCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userMixedProductCellArray = userMixedProductCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userCellArray = userCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        mixedCellArray = mixedCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        freeCellArray = freeCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      //if cell not found - exit
      if (!foundFlag) return

      //if cell found - reset all other cells count
      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
    },

    getItemsWithCells(item) {
      let array = []

      let itemsInStorage = item.data?.ProformProduct?.product?.productObject?.items_in_storage || item.data?.ProformProduct?.product?.productObject?.product_in_storage || []

      itemsInStorage.map(storageItem => {
        if(storageItem.cell && +storageItem.quantity > 0)
          array.push(storageItem)
      })
      return array
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../../../../../scss/colors";

  .order-admin-products{
    .red-color{
      color: $red
    }
    .green-color{
      color: $green
    }
  }

  .onlyPrintable{
    display: none;
  }

</style>
