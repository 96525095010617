var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['ordersInbound_AddProductCreate'])}}}),_vm._v(" "+_vm._s(_vm.$t('ordersInbound_AddProductCreate.localization_value.value'))+" ")]),_vm._l((_vm.products),function(item,index){return [_c('div',{key:index + '1',staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1),"rightBtn":true,"rightBtnType":'delete',"rightBtnText":_vm.$t('common_DeleteProduct.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.removeProformProduct(_vm.products, index)}}}),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'product_UPCCode',
                ])}}}),_c('DefaultInput',{class:{'ui-no-valid': item.validation.UPC},attrs:{"label":_vm.$t('product_UPCCode.localization_value.value'),"errorTxt":_vm.serverError ? item.validationTxt.UPC : _vm.$t(`${item.validationTranslate.UPC}.localization_value.value`),"error":item.validation.UPC},model:{value:(item.product.UPC),callback:function ($$v) {_vm.$set(item.product, "UPC", $$v)},expression:"item.product.UPC"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'product_SKU',
                ])}}}),_c('DefaultInput',{class:{'ui-no-valid': item.validation.SKU},attrs:{"label":_vm.$t('product_SKU.localization_value.value'),"errorTxt":_vm.serverError ? item.validationTxt.SKU : _vm.$t(`${item.validationTranslate.SKU}.localization_value.value`),"error":item.validation.SKU},model:{value:(item.product.SKU),callback:function ($$v) {_vm.$set(item.product, "SKU", $$v)},expression:"item.product.SKU"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'product_ProductName',
                ])}}}),_c('DefaultInput',{class:{'ui-no-valid': item.validation.productName},attrs:{"label":_vm.$t('product_ProductName.localization_value.value'),"errorTxt":_vm.serverError ? item.validationTxt.productName : _vm.$t(`${item.validationTranslate.productName}.localization_value.value`),"error":item.validation.productName},model:{value:(item.product.productName),callback:function ($$v) {_vm.$set(item.product, "productName", $$v)},expression:"item.product.productName"}})],1)])],1),_c('ProductItemCreation',{key:index,attrs:{"product":item,"serverError":_vm.serverError,"altVersion":true,"hideAddProfile":true},on:{"changeCategory":({val, nameVal}) => _vm.changeCategoryProform( {val, nameVal, item: _vm.products[index]}, _vm.ordersInbound.data.Order.getWarehouseCountry(), true),"changeProformSelectItem":function($event){_vm.changeProformSelectItem(_vm.products[index], _vm.ordersInbound.data.Order.getWarehouseCountry())}}})]}),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_AddProduct'])}}}),_c('MainButton',{staticClass:"wfc secondary",attrs:{"value":_vm.$t('common_AddProduct.localization_value.value'),"ico":'ico'},nativeOn:{"click":function($event){return _vm.addProformProduct(_vm.products)}}},[_c('template',{slot:"ico"},[_c('PlusAccentIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }