<template>

  <div class="fragment">

    <template v-if="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation'])"></div>
        {{$t('poshta_PersonalInformation.localization_value.value')}}
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_ContactName'])"></div>
          <DefaultInput
                  :label="$t('common_ContactName.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': personal.validation.personalName}"
                  :errorTxt="$t(`${personal.validationTranslate.personalName}.localization_value.value`)"
                  :error="personal.validation.personalName"
                  v-model="personal.information.personalName"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
          <DefaultInput
                  :label="$t('common_phone.localization_value.value')"
                  :type="'tel'"
                  v-bind:class="{'ui-no-valid': personal.validation.personalPhone}"
                  :errorTxt="$t(`${personal.validationTranslate.personalPhone}.localization_value.value`)"
                  :error="personal.validation.personalPhone"
                  v-model="personal.information.personalPhone"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
          <DefaultInput
                  :label="$t('ordersInbound_CompanyName.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': personal.validation.personalCompany}"
                  :errorTxt="$t(`${personal.validationTranslate.personalCompany}.localization_value.value`)"
                  :error="personal.validation.personalCompany"
                  v-model="personal.information.personalCompany"
          />
        </div>
      </div>

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress',])"></div>
        {{ $t('poshta_SenderAddress.localization_value.value') }}
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
          <DefaultInput
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.address}"
                  :errorTxt="$t(`${delivery.validationTranslate.address}.localization_value.value`)"
                  :error="delivery.validation.address"
                  v-model="delivery.delivery.address"
                  :maxLength="'35'"
                  :caption="$t('express_maxSymbols.localization_value.value')"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_addressSecond',])"></div>
          <DefaultInput
                  :label="$t('common_addressSecond.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.address2}"
                  :errorTxt="$t(`${delivery.validationTranslate.address2}.localization_value.value`)"
                  :error="delivery.validation.address2"
                  v-model="delivery.delivery.address2"
                  :maxLength="'35'"
                  :caption="$t('express_maxSymbols.localization_value.value')"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
          <DefaultInput
                  :label="$t('common_city.localization_value.value')"
                  v-bind:class="{'ui-no-valid': delivery.validation.city}"
                  :errorTxt="$t(`${delivery.validationTranslate.city}.localization_value.value`)"
                  :error="delivery.validation.city"
                  v-model="delivery.delivery.city"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
          <DefaultInput
                  :label="$t('common_region.localization_value.value')"
                  v-bind:class="{'ui-no-valid': delivery.validation.region}"
                  :errorTxt="$t(`${delivery.validationTranslate.region}.localization_value.value`)"
                  :error="delivery.validation.region"
                  v-model="delivery.delivery.region"
          />
        </div>
<!--        <div class="order-create__col custom-col custom-col&#45;&#45;33  custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
<!--             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['common_region'])"></div>-->
<!--          <DefaultSelect-->
<!--                  :options="optionsRegions"-->
<!--                  :label="$t('common_region.localization_value.value')"-->
<!--                  v-bind:class="{'ui-no-valid': delivery.validation.region}"-->
<!--                  :errorTxt="$t(`${delivery.validationTranslate.region}.localization_value.value`)"-->
<!--                  :error="delivery.validation.region"-->
<!--                  :optionsLabel="'label'"-->
<!--                  :selected="delivery.delivery.region"-->
<!--                  @change="changeRegion"-->
<!--          />-->
<!--        </div>-->
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
          <DefaultSelect
                  :options="options"
                  :label="$t('common_country.localization_value.value')"
                  v-bind:class="{'ui-no-valid': delivery.validation.country}"
                  :errorTxt="$t(`${delivery.validationTranslate.country}.localization_value.value`)"
                  :error="delivery.validation.country"
                  :optionsLabel="'label'"
                  :selected="delivery.delivery.country"
                  :disabled="true"
                  @change="changeCountry"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_zipCode'])"></div>
          <DefaultInput
                  :label="$t('common_zipCode.localization_value.value')"
                  v-bind:class="{'ui-no-valid': delivery.validation.zip}"
                  :errorTxt="$t(`${delivery.validationTranslate.zip}.localization_value.value`)"
                  :error="delivery.validation.zip"
                  v-model="delivery.delivery.zip"
          />
        </div>
      </div>

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_weight',])"></div>
        {{ $t('common_weight.localization_value.value') }}
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
          <DefaultInput
                  :label="$t('common_weightLb.localization_value.value')"
                  :type="'text'"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.weightLB}"
                  :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.weightLB}.localization_value.value`)"
                  :error="ordersInbound.data.Dimensions.validation.weightLB"
                  v-model="ordersInbound.data.Dimensions.data.weightLB"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
          <DefaultInput
                  :label="$t('common_weightOz.localization_value.value')"
                  :type="'text'"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': ordersInbound.data.Dimensions.validation.weightOZ}"
                  :errorTxt="$t(`${ordersInbound.data.Dimensions.validationTranslate.weightOZ}.localization_value.value`)"
                  :error="ordersInbound.data.Dimensions.validation.weightOZ"
                  v-model="ordersInbound.data.Dimensions.data.weightOZ"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";
  export default {
    name: "ShippingDetailsBlock",
    components: {
      DefaultSelect,
      DefaultInput
    },

    props: {
      ordersInbound: Object,
      personal: Object,
      delivery: Object,
    },

    data(){
      return {
        options: [],
        optionsRegions: [],
      }
    },

    mounted() {
      this.getCountry()
    },

    methods: {
      getCountry() {
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries);
          filterCountriesUSA(countries)
          this.options = countries
          if(this.ordersInbound.data.returnTypes.buyLabel === this.ordersInbound.data.returnType){
            this.changeCountry(this._.first(this.options))
          } else {
            this.changeCountry('')
          }
        })
      },

      changeCountry(val){
        this.delivery.setCountry(val)

        if(val && val !== '') {
          this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
            this.optionsRegions = this._.toArray(this.$store.getters.getRegions);
          })
        }

      },

      changeRegion(val) {
        this.delivery.setRegion(val)
      },
    }
  }
</script>

<style scoped>

</style>
