<template>
  <OrdersInboundDetailUser
          v-if="loadUserAuthorizedData"
          :ordersInbound="ordersInbound"
  />
</template>

<script>
  import OrdersInboundDetailUser from "./OrdersInboundDetailUser/OrdersInboundDetailUser";
  import {OrdersInbound} from "../../models/OrdersInbound";
  import {ORDER_INBOUND_STATUS} from "../../../../../staticData/staticVariables";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {ordersInboundMixin} from "../../../../../mixins/ordersInbound/ordersInboundMixin";
  import {ProformProduct} from "@/components/globalModels/ProformProduct";
  import {ProductForCell} from "@/components/globalModels/ProductForCell";

  export default {
    name: "OrdersInboundDetail",
    components: {
      OrdersInboundDetailUser,
    },

    mixins: [
      proformMixins,
      ordersInboundMixin,
    ],

    watch: {
      loadUserAuthorizedData: function () {
        this.initOrder()
      },

      downloadPermissions() {
        this.checkPermission()
      },
    },

    data() {
      return {
        ordersInbound: new OrdersInbound(),
        orderLoading: false,


        optionsStatus: [
          {
            id: ORDER_INBOUND_STATUS.STATUS_COMPLETE,
            translation: 'ordersInbound_statusCompleted',
            name: "Complete",
            value: 'complete',
          },
          {
            id: ORDER_INBOUND_STATUS.STATUS_IN_PROGRESS,
            translation: 'ordersInbound_statusProgress',
            name: "In Progress",
            value: 'in_progress',
          },
          {
            id: ORDER_INBOUND_STATUS.STATUS_DRAFT,
            translation: 'ordersInbound_statusDraft',
            name: "In draft",
            value: 'in_draft',
          },
          {
            id: ORDER_INBOUND_STATUS.STATUS_SHIPMENT,
            translation: 'ordersInbound_statusShipment',
            name: "Shipment",
            value: 'shipment',
          }
        ]
      }
    },

    mounted() {

      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      let orderId = this.$route.params.id

      this.ordersInbound.setOrderId(orderId)

      this.initializeProductProformArray(this.ordersInbound.data.ProformProducts)
      // this.initializeProductProformArray(this.ordersInbound.data.AddedProducts)

      if (this.loadUserAuthorizedData) {
        this.initOrder()
      }

      this.setOrderSystemTypesAndDeliverySystemByType(this.$route.params.type)
      this.getProductsConditionFc()

    },

    methods: {
      initOrder() {
        this.$store.dispatch('getOrderInbound', this.ordersInbound.data.Order.getId()).then(response => {
          let order = this.getRespData(response)
          this.orderLoading = true

          if (order['file_entries'].length > 0) {
            this.getFileFromServerFc(order['file_entries'])
          }

          this.ordersInbound.setItem(order, this.isAdmin)

          // Add products with response data
          // this.initialProformsForUser(order)
          if(this.isAdmin){
            this.initialProformsForAdmin(order)
          } else {
            this.initialProformsForUser(order)
          }


          /////   Admin

          /**
           * Записываются возможные статусы для редактирования админа
           */
          this.ordersInbound.setOptionsStatus(this.optionsStatus)

          /**
           * Получаем {} со статусом для селекта по statusId
           */
          let statusIndex = this._.findIndex(this.ordersInbound.getOptionsStatus(), ['id', order['status_id']])
          this.ordersInbound.setStatus(this.ordersInbound.getOptionsStatus()[statusIndex])

        }).catch(error => this.createErrorLog(error))
      },

      getFileFromServerFc(fileEntries) {
        let file = this._.first(fileEntries)
        this.$store.dispatch('getFileFromServer', file.id)
          .then((response) => {
            let dataFile = response
            let fileBase64 = dataFile
            Object.keys(dataFile).map(item => {
              dataFile[item] = {
                id: item,
                type: file.mime_type,
                uuid: file.uuid,
                base64: fileBase64[item]
              }
            })
            this.ordersInbound.setFiles(dataFile)
          })
      },

      initialProformsForAdmin(order) {
        let products = order['products']
        if (Object.keys(products).length > 0) {

          products.map((product, index) => {
            let {
              newProformModel,
              cells,
              // existCell
            } = this.createProductProformModelAndCellsArray(product, order, index)

            /**
             * set proformProduct to ProductForCell model
             * and add to productsForCellArray array
             */
            let newIndex = this.addProformProductForCells(newProformModel, cells, product.count_damaged)

            /**
             * Add Product Count Without Cell
             */
            this.addProductCountWithoutCell(product, this.ordersInbound.data.productsForCellArray[newIndex])

            /**
             * Add Product Exist Count Without Cell
             */
            this.addProductExistCountWithoutCell(product, this.ordersInbound.data.productsForCellArray[newIndex])


            /**
             * Sort ProductsForCellArray by storage quantity
             */
                // if(!existCell){
            let productsForCellArrayItem = this.ordersInbound.data.productsForCellArray[newIndex]

            let userArray = productsForCellArrayItem.data.UserProductCellArray
            let userMixedProductCellArray = productsForCellArrayItem.data.UserMixedProductCellArray

            productsForCellArrayItem.data.UserProductCellArray = this.sortCellArrayByStorageCount(userArray)
            productsForCellArrayItem.data.UserMixedProductCellArray = this.sortCellArrayByStorageCount(userMixedProductCellArray)

            // let userCellArray = this.ordersInbound.data.productsForCellArray[newIndex].data.UserCellArray
            // this.ordersInbound.data.productsForCellArray[newIndex].data.UserCellArray = this.sortCellArrayByStorageCount(userCellArray)
            // }
          })
        }

        this.ordersInbound.data.productsForCellArray.map((itemProductsForCell) => {
          let proforma = itemProductsForCell.data.ProformProduct
          this.initialProformSelectOptions({
            val: proforma.product.proformData.proformCategory,
            item: proforma
          })
        })
      },

      createProductProformModelAndCellsArray(product, order, index){
        /**
         * Generate data product and add to proformProduct model
         **/
        let currentProduct = product?.product
        let proformDataEntities = order['productProformData'][index]?.proformData
        // let proformDataEntities = this._.find(order['productProformData'], {product_id: currentProduct?.id}).proformData

        let entityId = proformDataEntities ? Object.keys(proformDataEntities)[0] : 0
        let proformData = null
        if (entityId > 0) {
          proformData = proformDataEntities[entityId]
        }
        else {
          proformData = {
            proform_category: '',
            proformOptions: {
              handmade: '1',
              item_price: 0,
              item_quantity: product.quantity,
            },
            proformTypes: null,
            values: [],
          }
        }

        let productData = {
          productInstance: currentProduct,
          proformData: proformData,
        }

        let newProformModel = new ProformProduct()
        this.initializeProductProformArray({product: newProformModel}, 'product')
        newProformModel.setProductItem(productData, entityId, this)
        newProformModel.setProductItemsQuantity(product.quantity)

        let userProductCells = []
        let userCells = []
        let freeCells = []
        let mixedFreeCells = []

        userProductCells = [...userProductCells, ...this.addExistCellsForProduct(product)]

        let existCell = userProductCells.length > 0

        userProductCells = [...userProductCells, ...this.addCellsWithProductInWarehouse(currentProduct, existCell, userProductCells)]

        userCells = [...userCells, ...this.addOtherUserCells(order, existCell, userProductCells)]

        freeCells = [...freeCells, ...this.addOtherFreeCells(order)]

        mixedFreeCells = [...mixedFreeCells, ...this.addMixedFreeCells(order)]

        return {newProformModel, cells: {userProductCells, userCells, freeCells, mixedFreeCells}, existCell}
      },

      addProformProductForCells(product = false, cells = false, countDamaged = false) {
        let productForCell = new ProductForCell(false, product, countDamaged)
        if(cells.userProductCells.length || cells.userCells.length || cells.freeCells.length || cells.mixedFreeCells.length){
          productForCell.setCellsByTypeInbound(cells)
        }

        this.ordersInbound.data.productsForCellArray.push(productForCell)
        return this.ordersInbound.data.productsForCellArray.length - 1
      },

      addProductCountWithoutCell(product, productForCell) {
        product.product.items_in_storage.map(productInWarehouse => {
          if(!productInWarehouse.cell && productInWarehouse.cell_id) return
          if(productInWarehouse.damage) return

          if(!productInWarehouse.cell) {
            productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(productInWarehouse.quantity)
          }
        })
      },

      addProductExistCountWithoutCell(product, productForCell) {
        product.cells.map(cell => {
          if(!cell.cell && cell.cell_id) return
          if(cell.damage) return

          if(!cell.cell) {
            productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
          }
        })
      },

      // getOrderPresetsPrice(){
      //   /**
      //    * Чтобы получить orderPrice проверется тип ордера
      //    * и вываливается список с ценами
      //    */
      //
      //
      //   let filter
      //   switch (this.$route.params.type){
      //     case 'fbm':
      //       filter = 'fee_order_fbm/list?filter[VipProcessFee]=1'
      //       break
      //     case 'fba':
      //       filter = 'fee_order_fba/list'
      //       break
      //   }
      //   return this.$store.dispatch('getOrderProcessFee', filter).then(response => {
      //     this.ordersInbound.setOrderProcessPriceList(this.getRespPaginateData(response))
      //   })
      // },
    },
  }
</script>

<style scoped>

</style>
