<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_documents', ])"></div>
      {{$t('ordersInbound_documents.localization_value.value')}}
      <!--      Documents-->
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col"
           v-if="documents.data.maxCountFiles - Object.keys(documents.data.downloadFiles).length > 0">
        <DropZone class="drop-zone-bg"
                  :parentFiles="documents.data.files"
                  :multiple="false"
                  :maxCount="documents.data.maxCountFiles - documents.data.downloadFiles.length"
                  :maxSize="documents.data.maxSizeFiles"
                  @changeImg="changeFile"
                  :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
        />
      </div>

      <div class="custom-col"
           v-if="Object.keys(documents.data.downloadFiles).length > 0"
      >
        <div class="custom-row product-img-row">
          <div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100">
            <DownloadFileBlock
                v-for="(item, index) in documents.data.downloadFiles"
                :key="index"
                :file="item"
                @removeFile="removeFile"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropZone from "../../../../../../coreComponents/DropZone/DropZone";
import DownloadFileBlock from "../../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
import {ordersInboundMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";

export default {
  name: "DocumentsBlock",
  components: {
    DropZone,
    DownloadFileBlock,
  },

  mixins: [
    ordersInboundMixin
  ],

  props: {
    ordersInbound: Object,
    documents: Object,
  },

  methods: {

  },
}
</script>

<style scoped>

</style>