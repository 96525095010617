import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// import {Product} from "../../../globalModels/Product";
import {User} from "../../../globalModels/User";
import {Order} from "../../../globalModels/Order";
import {Files} from "../../../globalModels/Files";
import {ProformProduct} from "../../../globalModels/ProformProduct";
import {ORDER_INBOUND_STATUS} from "../../../../staticData/staticVariables";
import {Product} from "../../../globalModels/Product";
import {ProductForCell} from "../../../globalModels/ProductForCell";
import {Dimensions} from "../../../globalModels/Dimensions";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Delivery} from "../../../globalModels/Delivery";

const moment = require('moment')
const _ = require('lodash');


export function OrdersInbound() {

	this.checkValid = checkValid

	this.data = {
		Order: new Order(),
		createdAt: '',
		User: new User(),

		orderInboundTypeId: '',
		fulfillment: '',
		return: false,

		deliveryServices: [],
		deliveryServicesId: '',

		condition: [],
		conditionSelected: '',

		shippingDate: null,
		trackingNumber: '',

		Documents: new Files({
			maxCountFiles: 1,
			maxSizeFiles: 3
		}),

		comment: '',
		checkAndRepack: false,

		// productDefault: [new ProformProduct()],
		ProformProducts: [new ProformProduct()],
		proformProductsDeletedIds: [],
		AddedProducts: [],

		returnType: 'has_label',
		returnTypes: {
			iHasLabel: 'has_label',
			buyLabel: 'buy_label'
		},

		processResults: [], //field after api request

		ProductForCell: new ProductForCell(),
		productsForCellArray: [],

		optionsStatus: [],
		status: '',
		receivedAt: null,
		orderProcessPriceList: [],
		orderProcessPrice: '',
		packagingPrice: '',
		shippingPrice: '',
		totalOrderProcessPrice: '',
		adminChangedTotalOrderProcessPrice: false,
		useTotalOrderProcessPrice: false,

		labelPrice: {},

		dimensionMeasureUnitId: '',

		Dimensions: new Dimensions(),

		Personal: new PersonalInformation(),
		Delivery: new Delivery(),

		idReturned: null,
	}

	this.validation = {
		deliveryServicesId: false,
		trackingNumber: false,
		shippingDate: false,
		receivedAt: false,

		noProducts: false,
	}

	this.validationTranslate = {
		deliveryServicesId: '',
		trackingNumber: '',
		shippingDate: '',
		receivedAt: '',

		noProducts: '',
	}

	this.validationTxt = {
		deliveryServicesId: false,
		trackingNumber: false,
		shippingDate: false,
		receivedAt: false,

		noProducts: false,
	}

	/**
	 * Getters
	 */

	this.getCreatedAt = () => {
		return this.data.createdAt
	}
	this.getDeliveryServices = () => {
		return this.data.deliveryServices
	}
	this.getOrderInboundTypeId = () => {
		return this.data.orderInboundTypeId
	}
	this.getFulfillment = () => {
		return this.data.fulfillment
	}
	this.getReturn = () => {
		return this.data.return
	}
	this.getDeliveryServicesId = () => {
		return this.data.deliveryServicesId
	}
	this.getCondition = () => {
		return this.data.condition
	}
	this.getConditionSelected = () => {
		return this.data.conditionSelected
	}
	this.getShippingDate = () => {
		return this.data.shippingDate
	}
	this.getTrackingNumber = () => {
		return this.data.trackingNumber
	}
	this.getComment = () => {
		return this.data.comment
	}
	this.getCheckAndRepack = () => {
		return this.data.checkAndRepack
	}
	this.getReturnType = () => {
		return this.data.returnType
	}
	this.getProcessResults = () => {
		return this.data.processResults
	}
	// this.getProductDefault = () => {
	// 	return this.data.productDefault
	// }
	this.getProductsForCellArray = () => {
		return this.data.productsForCellArray
	}
	this.getOptionsStatus = () => {
		return this.data.optionsStatus
	}
	this.getStatus = () => {
		return this.data.status
	}
	this.getReceivedAt = () => {
		return this.data.receivedAt
	}
	this.getOrderProcessPriceList = () => {
		return this.data.orderProcessPriceList
	}
	this.getOrderProcessPrice = () => {
		return this.data.orderProcessPrice
	}
	this.getTotalOrderProcessPrice = () => {
		return this.data.totalOrderProcessPrice
	}
	this.getAdminChangedTotalOrderProcessPrice = () => {
		return this.data.adminChangedTotalOrderProcessPrice
	}
	this.getUseTotalOrderProcessPrice = () => {
		return this.data.useTotalOrderProcessPrice
	}
	this.getPackagingPrice = () => {
		return this.data.packagingPrice
	}
	this.getShippingPrice = () => {
		return this.data.shippingPrice
	}
	this.getIdReturned = () => {
		return this.data.idReturned
	}

	/**
	 * Setters
	 */
	this.setOrderId = function (val) {
		this.data.Order.setId(val)
	}
	this.setCreatedAt = function (val) {
		this.data.createdAt = val
	}
	this.setOrderInboundTypeId = function (val) {
		this.data.orderInboundTypeId = val
	}
	this.setFulfillment = function (val) {
		this.data.fulfillment = val
	}
	this.setReturn = function (val) {
		this.data.return = val
	}
	this.setDeliveryServices = (val) => {
		this.data.deliveryServices = val
	}
	this.setDeliveryServicesId = (val) => {
		this.data.deliveryServicesId = val
	}
	this.setCondition = (val) => {
		this.data.condition = val
	}
	this.setConditionSelected = (val) => {
		this.data.conditionSelected = val
	}
	this.setShippingDate = (val) => {
		this.data.shippingDate = val
	}
	this.setTrackingNumber = (val) => {
		this.data.trackingNumber = val
	}
	this.setComment = (val) => {
		this.data.comment = val
	}
	this.setCheckAndRepack = (val) => {
		this.data.checkAndRepack = val
	}
	this.setReturnType = (val) => {
		this.data.returnType = val
	}
	this.setProcessResults = (val) => {
		this.data.processResults = val
	}
	this.setProductDefault = (val) => {
		if(val === ''){
			this.data.productsDefault = [new Product()]
		}
	}
	this.setProductsForCellArray = (val) => {
		this.data.productsForCellArray = val
	}
	this.setOptionsStatus = (val) => {
		this.data.optionsStatus = val
	}
	this.setStatus = (val) => {
		this.data.status = val
	}
	this.setReceivedAt = (val) => {
		this.data.receivedAt = val
	}
	this.setOrderProcessPriceList = (val) => {
		this.data.orderProcessPriceList = val
	}
	this.setOrderProcessPrice = (val) => {
		this.data.orderProcessPrice = val
	}
	this.setTotalOrderProcessPrice = (val) => {
		this.data.totalOrderProcessPrice = val
	}
	this.setAdminChangedTotalOrderProcessPrice = (val) => {
		this.data.adminChangedTotalOrderProcessPrice = val
	}
	this.setUseTotalOrderProcessPrice = (val) => {
		this.data.useTotalOrderProcessPrice = val
	}
	this.setPackagingPrice = (val) => {
		this.data.packagingPrice = val
	}
	this.setShippingPrice = (val) => {
		this.data.shippingPrice = val
	}
	this.setIdReturned = (val) => {
		this.data.idReturned = val
	}
}


/**
 * Global Setters
 */

OrdersInbound.prototype.setItem = function (item, isAdmin, edit = false) {
	let Dimensions = this.data.Dimensions
	let Delivery = this.data.Delivery
	let Personal = this.data.Personal

	this.data.Order.setId(item['id'])
	this.data.Order.setHistoryOfChanged(item['activityUUID'])
	this.data.Order.setWarehouse(item['warehouse_storage'])
	this.data.Order.setOldStatus(item['status_id'])
	this.data.User.setUser(item['user'])

	this.setDeliveryServicesId(item['delivery_service_id'] ? item['delivery_service_id'] : '')
	this.setCreatedAt(item['created_at'] ? item['created_at'] : '')
	this.setShippingDate(item['shipped_at'] ? item['shipped_at'] : '')
	this.setComment(item['comment'] ? item['comment'] : '')
	this.setTrackingNumber(item['tracking_number'] ? item['tracking_number'] : '')
	this.setReturnType(item['label_presence'] ? item['label_presence'] : '')
	this.setConditionSelected(item['product_condition'] ? item['product_condition'] : '')
	this.setCheckAndRepack(item['check_and_repack'] ? item['check_and_repack'] : false)

	this.setIdReturned(item['return_parcel_id'])

	if(!item['received_at'] && isAdmin && edit){
		this.setReceivedAt(moment(new Date()).format('YYYY-MM-DD'))
	} else {
		this.setReceivedAt(item['received_at'] ? item['received_at'] : '')
	}

	this.setPackagingPrice(item['packaging_price'] ? item['packaging_price'] : '')
	this.setShippingPrice(item['shipping_price'] ? item['shipping_price'] : '')
	// this.setOrderProcessPrice(item['order_process_price'] ? item['order_process_price'] : '')

	Dimensions.setDimensionsLength(item['length'] ? item['length'] : '')
	Dimensions.setDimensionsWidth(item['width'] ? item['width'] : '')
	Dimensions.setDimensionsHeight(item['height'] ? item['height'] : '')
	Dimensions.setDimensionsWeightLB(item['weight_lb'] ? parseFloat(item['weight_lb']).toFixed(2) : '')
	Dimensions.setDimensionsWeightOZ(item['weight_oz'] ? parseFloat(item['weight_oz']).toFixed(2) : '')

	if(item['sender_address']){
		Delivery.setDeliveryItem({
			address: item['sender_address'],
			address2: item['sender_address_line_2'],
			city: item['sender_city'],
			region: item['sender_region'],
			country: {
				id: item['sender_country']['id'],
				label: item['sender_country']['name'],
			},
			zip: item['sender_zip'],
		})
	}

	if(item['sender_customer_name']){
		Personal.setPersonalItem({
			personalName: item['sender_customer_name'],
			personalPhone: item['sender_phone'],
			personalCompany: item['sender_company_name'],
		})
	}

	this.setStatus(item['status_id'])


	this.setTotalOrderProcessPrice(item.total_order_process_price)
	this.setAdminChangedTotalOrderProcessPrice(item.admin_changed_total_order_process_price)
	this.setUseTotalOrderProcessPrice(item.use_total_order_process_price)

	this.setOrderProcessPriceList(item.order_process_fees)
	this.initOrderProcessPrice(item, isAdmin)


	this.data.User.setUser(item.user)

}

OrdersInbound.prototype.writingDataCurrentUser = function (userInformation) {
	let data = this.data

	data.Personal.setPersonalItem({
		personalName: userInformation.user_full_name,
		personalPhone: userInformation.phone.replace(/[^\d]/g, ''),
		personalCompany: userInformation.company_name,
	})

	data.Delivery.setDeliveryItem({
		address: userInformation.address,
		city: userInformation.city,
		region: userInformation?.region?.name || null,
		country: {
			id: userInformation.country.id,
			label: userInformation.country.name,
		},
		zip: userInformation.zip
	})
}


/**
 * Functions
 */

OrdersInbound.prototype.initOrderProcessPrice = function (item, isAdmin) {
	if(item.order_process_price){
		this.setOrderProcessPrice(_.find(item.order_process_fees, {cost: parseFloat(item.order_process_price)}))
		return
	}

	// if(isAdmin){
	// 	let userSettingProcessFee = item.user?.user_setting?.order_process_fee
	// 	if(userSettingProcessFee){
	// 		this.setOrderProcessPrice(userSettingProcessFee)
	// 	} else {
	// 		this.setOrderProcessPrice(_.find(item.order_process_fees, {use_default: true}))
	// 	}
	// }
	if(isAdmin){
		//set 0 cost by default for shipments
		this.setOrderProcessPrice(_.find(item.order_process_fees, {cost: 0}))
	}
}

OrdersInbound.prototype.resetProformProducts = function () {
	this.data.ProformProducts = [new ProformProduct()]
}

OrdersInbound.prototype.addProformProduct = function (array = false) {
	array.push(new ProformProduct)
	return array.length - 1
}

OrdersInbound.prototype.removeProformProduct = function (array, index) {
	this.data.proformProductsDeletedIds.push(array[index].product.proformEntityId)
	array.splice(index, 1)
}

OrdersInbound.prototype.addProductForCells = function (product = false, cells = false) {
	let productForCell = new ProductForCell(product)
	if(cells.length > 0){
		productForCell.setCellsByType(cells)
	}
	this.data.productsForCellArray.push(productForCell)
	return this.data.productsForCellArray.length - 1
}

// OrdersInbound.prototype.getTotalSumProformProduct = function () {
//   let totalSum = 0
//
//   this.data.ProformProducts.map(item => {
//     totalSum += parseFloat(item.product.price * item.product.itemsQuantity)
//   })
//   return parseFloat(totalSum)
// }

// OrdersInbound.prototype.setAccountUser = function (user) {
//   this.setUserId(user.id)
// }

OrdersInbound.prototype.setFiles = function (val) {
	this.data.Documents.pushDownloadFiles(val)
}

OrdersInbound.prototype.removeFile = function (id) {
	let i = -1
	_.findIndex(this.data.Documents.getDownloadFiles(), function (item, index) {
		if (item[id] !== undefined) i = index
	});

	this.data.Documents.removeDownloadFile(i)
}

OrdersInbound.prototype.productsDataGeneration = function (array, withCells = false) {
	let data = []
	array.map(item => {
		let product = item.data.ProformProduct.product
		let productId = product.productObject?.id

		if(!productId) return

		let proformProduct = item.data.ProformProduct.prepareSaveProform()

		let itemData = {
			"product_id": productId,
			"quantity": product.itemsQuantity,
			"count_damaged": item.data.damagedCount,
			"count_scanned_kyiv": item.data.scannedInKievCount,
			"length": null,
			"width": null,
			"height": null,
			"proform_data": proformProduct,
		}

		if(withCells){
			let cells = [],
				userProductCellArray = item.data.UserProductCellArray,
				userMixedProductCellArray = item.data.UserMixedProductCellArray,
				userCellArray = item.data.UserCellArray,
				mixedCellArray = item.data.MixedCellArray,
				freeCellArray = item.data.FreeCellArray

			cells = [...cells, ...this.getCellsByArray(userProductCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(userMixedProductCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(userCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(freeCellArray, productId)]

			if(parseInt(item.data.withoutCellEndCounts) !== 0) {
				cells.push({
					"product_id": productId,
					"cell_id": null,
					"item_quantity": parseInt(item.data.withoutCellEndCounts),
				})
			}

			itemData["cells"] = cells
		}
		data.push(itemData)
	})
	return data
}

OrdersInbound.prototype.getCellsByArray = function (array, productId) {
	let cells = []
	array.map(itemCell => {
		if(itemCell.data.cellCount > 0){
			cells.push({
				"product_id": productId,
				"cell_id": itemCell.data.cellSelected.id,
				"item_quantity": parseInt(itemCell.data.cellCount),
			})
		}
	})
	return cells
}

OrdersInbound.prototype.removeProductForCells = function (array, index) {
	this.data.proformProductsDeletedIds.push(array[index].data.ProformProduct.product.proformEntityId)
	if (array.length === 1) {
		array.splice(index, 1)
		array.push(new ProductForCell)
		return
	}
	array.splice(index, 1)
}

/**
 * Validations
 */

OrdersInbound.prototype.getValidationProductsByCount = function (isHasSelectedProductsExisting, isHasSelectedProductsNew) {
	/**
	 * Validation "Products"
	 * if order don't has selected "ProductsExisting" or "ProductsNew" has selected item
	 */

	return isHasSelectedProductsExisting || isHasSelectedProductsNew
}

OrdersInbound.prototype.productsValidation = function (products, newProducts) {
	let valid = true

	if(products){
		this.data.ProformProducts.map(item => {
			if (!item.productProformValidation({
				productName: true,
				proformCategory: true,
				itemsQuantity: true,
				price: true,
				proformMaterial: true,
			})) valid = false
		})
	}

	if(newProducts){
		if(this.data.AddedProducts.length > 0){
			this.data.AddedProducts.map(item => {
				if (!item.productProformValidation({
					productName: true,
					productNameLatin: true,
					proformCategory: true,
					itemsQuantity: true,
					price: true,
					proformMaterial: true
				})) valid = false
			})
		}
	}

	return valid
}

OrdersInbound.prototype.isHasSelectedProductsExisting = function () {
	let selected = false
	this.data.ProformProducts.map(item => {
		if (item.product.productObject.id &&
			item.product.productObject.id !== '') {
			selected = true
		}
	})
	return selected;
}

OrdersInbound.prototype.isHasSelectedProductsNew = function () {
	let selected = false
	if(this.data.AddedProducts.length > 0){
		selected = true
	}
	return selected;
}

OrdersInbound.prototype.orderAPIValidate = function () {
	let data = this.data

	let validationItems = {
		orderProcessPrice: data.orderProcessPrice,
		packagingPrice: data.packagingPrice,
		shippingPrice: data.shippingPrice,
	}


	let validationOptions = {
		deliveryServicesId: {type: ['empty']},
		shippingDate: {type: ['empty']},
		trackingNumber: {type: ['empty']},
	}

	return this.checkValid(validationItems, validationOptions)
}


OrdersInbound.prototype.stepAValidation = function (
	{
		deliveryServicesId,
		shippingDate,
		trackingNumber
	}
) {

	let data = this.data

	let validationItems = {
		deliveryServicesId: data.deliveryServicesId,
		shippingDate: data.shippingDate,
		trackingNumber: data.trackingNumber,
	}


	let validationOptions = {
		deliveryServicesId: {type: deliveryServicesId ? ['empty'] : []},
		shippingDate: {type: shippingDate ? ['empty'] : []},
		// trackingNumber: {type: trackingNumber ? ['empty'] : []},
	}

	if(this.data.returnTypes.buyLabel !== this.data.returnType){
		validationOptions['trackingNumber'] = {type: trackingNumber ? ['empty'] : []}
	}

	return (this.checkValid(validationItems, validationOptions) && this.data.Documents.fileSizeValidation())
}

OrdersInbound.prototype.stepBValidation = function (that) {

	let isHasSelectedProductsExisting = this.isHasSelectedProductsExisting()
	let isHasSelectedProductsNew = this.isHasSelectedProductsNew()
	let valid = true

	let validateProducts = false
	let validateNewProducts = false

	if(isHasSelectedProductsExisting){
		validateProducts = true
	}
	if(isHasSelectedProductsNew){
		validateNewProducts = true
	}



	if(!isHasSelectedProductsExisting && !isHasSelectedProductsNew){
		valid = false
		validateProducts = true

		this.validation.noProducts = true
		this.validationTranslate.noProducts = 'error_required'
	}
	else {
		this.validation.noProducts = false
		this.validationTranslate.noProducts = ''
	}

	if(this.checkDuplicateProduct()) {
		that.openNotify('error', 'common_notificationHasDuplicateProduct')
		valid = false
	}

	return this.productsValidation(validateProducts, validateNewProducts) && valid
}

OrdersInbound.prototype.checkDuplicateProduct = function () {
	return this.data.ProformProducts.some((productForCell, index) => {
		return this.data.ProformProducts.some((proformProductFind, indexFind) => {
			if(index !== indexFind) {
				return proformProductFind.product.productObject.id === productForCell.product.productObject.id
			} else {
				return false
			}
		})
	})
}

OrdersInbound.prototype.stepCValidation = function (
	{
		deliveryServicesId,
		personal,
		delivery,
		weight,
	}
) {

	let valid = true
	let data = this.data

	let validationItems = {
		deliveryServicesId: data.deliveryServicesId,
	}


	let validationOptions = {
		deliveryServicesId: {type: deliveryServicesId ? ['empty'] : []},
	}

	if(personal){
		let validPersonal = data.Personal.personalInfoPartsValidate({
			personalName: true,
			personalPhone: true,
		})
		if(!validPersonal) valid = false
	}
	if(delivery){
		let validDelivery = data.Delivery.deliveryInfoPartialValidate({
			address: true,
			address2: true,
			city: true,
			region: true,
			country: true,
			zip: true,
			latin: true,
		})
		if(!validDelivery) valid = false
	}

	if(weight){
		let validDimensions = data.Dimensions.dimensionsPartialValidate({
			weightLB: data.Dimensions.data.weightOZ === '',
			weightOZ: data.Dimensions.data.weightLB === '',
		})
		if(!validDimensions) valid = false
	}

	return (valid && this.checkValid(validationItems, validationOptions))
}

OrdersInbound.prototype.adminValidation = function (edit = false, that) {
	let userValidation = this.data.User.userValidation({
		userId: true
	})

	// let flagStepC = (this.getOrderInboundTypeId() === ORDER_INBOUND_TYPE.FBMReturn.id)

	let validationItems = {
		shippingDate: this.data.shippingDate,
		// trackingNumber: this.data.trackingNumber,
		receivedAt: this.data.receivedAt,
	}

	let validationOptions = {
		shippingDate: {type: this.data.status.id === ORDER_INBOUND_STATUS.STATUS_COMPLETE ? ['empty'] : []},
		receivedAt: {type: this.data.status.id === ORDER_INBOUND_STATUS.STATUS_COMPLETE ? ['empty'] : []},
		// trackingNumber: {type: ['empty']},
	}

	let stepAValidation = this.stepAValidation({
		// deliveryServicesId: true,
		trackingNumber: true,
		shippingDate: true,
	})

	let stepBValidation = true
	if(!edit){
		stepBValidation = this.stepBValidation(that)
	}


	return (userValidation && stepAValidation && stepBValidation &&
		(this.checkValid(validationItems, validationOptions)))
}


OrdersInbound.prototype.InboundCellCountsValidate = function (isAdminManage) {
	if(!isAdminManage) return true

	let valid = true
	this.data.productsForCellArray.map(product => {
		if(product.data.productCount !== product.data.scannedCount) {
			valid = false
		}
	})

	return valid
}


OrdersInbound.prototype.ScannedInKievValidation = function () {

	let valid = true
	this.data.productsForCellArray.map(product => {
		if(product.data.scannedInKievCount === 0) {
			valid = false
		}
	})

	return valid
}



/**
 * Prepare Data
 */


OrdersInbound.prototype.prepareProductsAfterMassStore = function () {

	let productsAdd = this.data.AddedProducts,
		dataProducts = [],
		processResults = this.getProcessResults()

	if(productsAdd.length > 0) {
		productsAdd.map((item, index) => {
			let product = {}
			let proformProduct = item.prepareSaveProform()
			product['product_id'] = processResults[index]?.id
			product['quantity'] = proformProduct['item_quantity']
			product['count_damaged'] = null
			product['length'] = null
			product['width'] = null
			product['height'] = null
			product['proform_data'] = proformProduct

			dataProducts.push(product)
		})
	}

	return dataProducts
}

OrdersInbound.prototype.prepareItems = function () {
	let items = [],
		productsNew = this.data.AddedProducts

	productsNew.map(item => {
		let data = item.prepareProduct()
		data['user_id'] = this.data.User.getUserId()
		items.push(data)
	})

	return items
}

OrdersInbound.prototype.prepareSave = function ({isAdmin = false, edit = false}) {

	let proformProducts = this.data.ProformProducts
	let Dimensions = this.data.Dimensions,
		Personal = this.data.Personal,
		Delivery = this.data.Delivery,
		productsForCellArray = this.data.productsForCellArray

	let prepareData = {
		'user_id': this.data.User.getUserId(),
		'order_inbound_type_id': this.getOrderInboundTypeId(),
		'status_id': ORDER_INBOUND_STATUS.STATUS_IN_PROGRESS,

		'fulfillment': this.getFulfillment(),
		'return': this.getReturn(),
		'check_and_repack': this.getCheckAndRepack(),
		'label_presence': this.getReturnType() ? this.getReturnType() : null,
		'delivery_service_id': this.getDeliveryServicesId(),
		'shipped_at': this.getShippingDate() ? moment(this.getShippingDate()).format('YYYY-MM-DD') : '',
		'tracking_number': this.getTrackingNumber() ? this.getTrackingNumber() : '',
		'comment': this.getComment() ? this.getComment() : null,
		'product_condition_id': this.getConditionSelected() ? this.getConditionSelected()['id'] : null,

		'storage_id': this.data.Order.getWarehouse()?.id,
		'products': [],
	}

	if(this.getIdReturned()) {
		prepareData['return_parcel_id'] = parseInt(this.getIdReturned())
	}


	if(Object.keys(this.data.labelPrice).length > 0){
		prepareData['rate'] = this.data.labelPrice.rate
	}



	// if (this.FBM.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id) {}


	let products = []

	/**
	 * if exist first product
	 */
	if((!isAdmin || (isAdmin && !edit)) && proformProducts.length > 0 && _.first(proformProducts).getProductObject()?.id){
		proformProducts.map(item => {
			let product = {}
			let proformProduct = item.prepareSaveProform()

			product['product_id'] = item.getProductObject()?.id ? item.getProductObject()?.id : null
			product['quantity'] = proformProduct['item_quantity']
			product['count_damaged'] = null
			product['length'] = null
			product['width'] = null
			product['height'] = null
			product['proform_data'] = proformProduct
			products.push(product)
		})
	}

	if(isAdmin && edit){
		products = this.productsDataGeneration(productsForCellArray, isAdmin)
	}


	/**
	 * if has created products through mass store
	 */
	if(this.data.AddedProducts.length > 0){
		this.prepareProductsAfterMassStore().map(item => {
			products.push(item)
		})
	}

	prepareData['products'] = products

	/**
	 * if field BUY_LABEL = false
	 */
	if(this.data.returnTypes.buyLabel !== this.data.returnType) {
		let userInformation, user = this.data.User.getUser()
		if(user?.contacts){
			userInformation = _.first(user?.contacts)
		} else {
			userInformation = user?.user_personal_contact
		}
		this.writingDataCurrentUser(userInformation)
	}

	prepareData['weight_lb'] = Dimensions.getDimensionsWeightLB() ? Dimensions.getDimensionsWeightLB() : null
	prepareData['weight_oz'] = Dimensions.getDimensionsWeightOZ() ? Dimensions.getDimensionsWeightOZ() : null

	prepareData['sender_company_name'] = Personal.getPersonalCompany() ? Personal.getPersonalCompany() : null
	prepareData['sender_customer_name'] = Personal.getPersonalName() ? Personal.getPersonalName() : null
	prepareData['sender_phone'] = Personal.getPersonalPhone() ? Personal.getPersonalPhone() : null

	prepareData['sender_country_id'] = Delivery.getCountry() ? Delivery.getCountry().id : null
	prepareData['sender_region'] = Delivery.getRegion() ? Delivery.getRegion() : null
	prepareData['sender_city'] = Delivery.getCity() ? Delivery.getCity() : null
	prepareData['sender_address'] = Delivery.getAddress() ? Delivery.getAddress() : null
	prepareData['sender_address_line_2'] = Delivery.getAddress2() ? Delivery.getAddress2() : null
	prepareData['sender_zip'] = Delivery.getZip() ? Delivery.getZip() : null


	if(isAdmin){
		prepareData['status_id'] = this.getStatus() ? this.getStatus().id : ORDER_INBOUND_STATUS.STATUS_IN_PROGRESS,
		prepareData['order_process_price'] = this.getOrderProcessPrice() ? this.getOrderProcessPrice().cost : null
		prepareData['packaging_price'] = this.getPackagingPrice() ? this.getPackagingPrice() : null
		prepareData['shipping_price'] = this.getShippingPrice() ? this.getShippingPrice() : null

		prepareData['dimension_measure_unit_id'] = null

		prepareData['length'] = Dimensions.getDimensionsLength() ? Dimensions.getDimensionsLength() : null
		prepareData['width'] = Dimensions.getDimensionsWidth() ? Dimensions.getDimensionsWidth() : null
		prepareData['height'] = Dimensions.getDimensionsHeight() ? Dimensions.getDimensionsHeight() : null

		if(this.getStatus().id === ORDER_INBOUND_STATUS.STATUS_COMPLETE){
			prepareData['received_at'] = this.getReceivedAt() ? moment(this.getReceivedAt()).format('YYYY-MM-DD') : null
		}

		if(this.getUseTotalOrderProcessPrice() &&
			(prepareData['order_process_price'] !== null || this.getAdminChangedTotalOrderProcessPrice())
		) {
			prepareData['total_order_process_price'] = this.getTotalOrderProcessPrice()
		}

		// prepareData['weight_lb'] = Dimensions.getDimensionsWeightLB() ? Dimensions.getDimensionsWeightLB() : null
		// prepareData['weight_oz'] = Dimensions.getDimensionsWeightOZ() ? Dimensions.getDimensionsWeightOZ() : null
		//
		// prepareData['sender_company_name'] = Personal.getPersonalCompany() ? Personal.getPersonalCompany() : null
		// prepareData['sender_customer_name'] = Personal.getPersonalName() ? Personal.getPersonalName() : null
		// prepareData['sender_phone'] = Personal.getPersonalPhone() ? Personal.getPersonalPhone() : null
		//
		// prepareData['sender_country_id'] = Delivery.getCountry() ? Delivery.getCountry().id : null
		// prepareData['sender_region'] = Delivery.getRegion() ? Delivery.getRegion() : null
		// prepareData['sender_city'] = Delivery.getCity() ? Delivery.getCity() : null
		// prepareData['sender_address'] = Delivery.getAddress() ? Delivery.getAddress() : null
		// prepareData['sender_zip'] = Delivery.getZip() ? Delivery.getZip() : null
	}

	if(edit) {
		prepareData['deleted_proform_ids'] = {
			proform_data: this.data.proformProductsDeletedIds,
		}
		this.data.proformProductsDeletedIds = []
	}

	return prepareData

}

