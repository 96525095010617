<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section noPrintable">
          <Setting
              :ordersInbound="ordersInbound"
              :user="ordersInbound.data.User"
          />
        </div>
        <div class="detail-cards__section">
          <Products
              :ordersInbound="ordersInbound"
              :user="ordersInbound.data.User"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <Shipping
              :ordersInbound="ordersInbound"
              :user="ordersInbound.data.User"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <Dimensions
              :ordersInbound="ordersInbound"
          />
        </div>
        <div class="detail-cards__section detail-cards__section--btn noPrintable">
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_cancel'])"></div>
            <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse">
              <span class="site-link site-link--alt brown">
                          {{ $t('common_cancel.localization_value.value') }}
              </span>
            </router-link>
          </div>
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_update'])"></div>
            <MainButton
                class="sharp"
                :value="$t('common_update.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"
                @click.native="$emit('save', {type: $route.params.type, isAdmin: isAdmin, edit: true})"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside noPrintable">
        <div class="detail-cards__aside-inner">
<!--              :order="ordersInbound.data.Order"-->
          <OrderCard
              :ordersInbound="ordersInbound"
              :user="ordersInbound.data.User"
              :personal="ordersInbound.data.Personal"
              :delivery="ordersInbound.data.Delivery"
              @reload="reload"

          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  // import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  // import UserInfo from "./components/UserInfo/UserInfo";
  import OrderCard from "./components/OrderCard/OrderCard";
  import Setting from "./components/Setting/Setting";
  import Shipping from "./components/Shipping/Shipping";
  import Products from "./components/Products/Products";
  import Dimensions from "./components/Dimensions/Dimensions";

  export default {
    name: "OrdersInboundEditAdmin",
    components: {
      MainButton,
      // CardLeftBlock,
      // UserInfo,
      OrderCard,
      Setting,
      Shipping,
      Products,
      Dimensions,
    },

    props: {
      ordersInbound: Object,
      user: Object,
    },

    data() {
      return {
        keyReload: -1,
      }
    },

    mounted() {

    },

    methods: {
      reload() {
        this.keyReload = Math.random()
      },
    }
  }
</script>

<style scoped>

</style>
