<template>
  <div>
    <OrdersInboundHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <OrdersInboundFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <OrdersInboundTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          :selectedNow="selectedNow"
          @reload="$emit('reload')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @thermalPrint="(data) => $emit('thermalPrint', data)"
          @getInboundOrderMarking="(data) => $emit('getInboundOrderMarking', data)"
      />
    </div>
  </div>
</template>

<script>


  import OrdersInboundHead from "./OrdersInboundHead/OrdersInboundHead";
  import OrdersInboundTable from "./OrdersInboundTable/OrdersInboundTable";
  import OrdersInboundFilter from "./OrdersInboundFilter/OrdersInboundFilter";

  export default {
    name: "OrdersInboundTableContent",

    components: {
      OrdersInboundHead,
      OrdersInboundTable,
      OrdersInboundFilter,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
        isModalStatusOrderDetailsPopup: false
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },


    }
  }
</script>

<style scoped>

</style>


