<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size"
  >
    <template slot="header">
      <span
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_changeInfo'])"></div>
         {{ $t('common_changeInfo.localization_value.value') }}
      </span>
<!--      Change Address-->
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">

          <template v-for="(item, index) in dataItems">
            <div class="custom-col  custom-col--md-100"  :key="index"
                 v-bind:class="{
                  'custom-col--50': dataItems.length > 1 && item.type !== 'radio',
                  'd-flex': item.type === 'radio',
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                 }">
              <div class="admin-edit" @click="editTranslate([item.name])"></div>

              <template v-if="item.type === 'inputSum'">
                <InputSum
                        :label="$t(`${item.name}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': item.valid}"
                        :error="item.valid"
                        :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                        v-model="item.value"
                />
                        <!--@change="(val) => ordersInbound.setOrderProcessPrice(val)"-->
              </template>

              <template v-if="item.type === 'text'">
                <DefaultInput
                    :label="$t(`${item.name}.localization_value.value`)"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :type="item.type"
                    v-model="item.value"
                />
              </template>

              <template v-if="item.field === 'country'">
                <DefaultSelect
                    :options="options"
                    :label="$t(`${item.name}.localization_value.value`)"
                    :optionsLabel="'label'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="item.value"
                    @change="val => {changeCountry(val, index)}"
                />
              </template>

              <template v-if="item.field === 'orderPrice'">
                <DefaultSelect
                        :options="$store.getters.getOrdersFBMItem.order_fees_list"
                        :label="$t(`${item.name}.localization_value.value`)"
                        :optionsLabel="'cost'"
                        :selected="item.value"
                        @change="val => {changeOrderFee(val, index)}"
                />
              </template>

              <template v-if="item.type === 'select'">
                <DefaultSelect
                        :options="item.options"
                        :label="$t(`${item.name}.localization_value.value`)"
                        :optionsLabel="item.optionsLabel"
                        :selected="item.value"
                        @change="val => {item.value = val; $emit('changeSelect', items)}"
                />
              </template>

              <template v-if="item.type === 'textarea'">
                <TextareaDefault
                        :label="$t(`${item.name}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': item.valid}"
                        :error="item.valid"
                        :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                        v-model="item.value"
                />
              </template>


              <template v-if="item.type === 'radio' && item.field === 'packaging'">
                <div class="custom-row">
                  <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc">
                    <RadioDefault
                            :label="'None'"
                            name="fedexRadioList2"
                            :value="true"
                            @input="changeGiftPaper('', index)"
                    />
                  </div>

                  <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc d-flex"
                       v-for="(itemGiftPaper, indexGiftPapers) in giftPapers"
                       :key="indexGiftPapers"
                  >
                    <RadioDefault
                            class="wfc"
                            :label="itemGiftPaper.label"
                            name="fedexRadioList2"
                            :value="_.has(item.value, 'id') && item.value.id === itemGiftPaper.id"
                            @input="changeGiftPaper(itemGiftPaper, index)"
                    />

                    <v-popover
                            class="site-tooltip site-tooltip--radio-after"
                            offset="5"
                            placement="top-left"
                            trigger="hover"
                    >

                      <TooltipBtn/>

                      <template slot="popover">
                        <div class="box-tooltip">
                          <div class="box-tooltip__img">
                            <img v-if="itemGiftPaper.small_image_file"
                                 :src="'data:' + itemGiftPaper.small_image_file.mime_type + ';base64,' + itemGiftPaper.imageBase64"
                                 alt="img"
                            >
                            <img v-else src="/img/common/box-birthday-img.png" alt="img">
                          </div>
                          <div class="box-tooltip__content">
                            <p>
                              <b>{{'Happy Birthday'}}</b>
                            </p>
                            <p>
                              {{'There package depends on the dimentions of your order. We Show just and example'}}
                            </p>
                          </div>
                        </div>

                      </template>
                    </v-popover>
                  </div>
                </div>

              </template>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_save'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
<!--          Cancel-->
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="$emit('save', items)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../services/filterCountries";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";

  export default {
    name: "ChangeDataPopup",

    props: {
      items: Array,
      // itemGiftPapers: {
      //   type: Object,
      //   default: {}
      // },
    },

    components: {
      InputSum,
      TooltipBtn,
      RadioDefault,
      DefaultSelect,
      TextareaDefault,
      Modal,
      MainButton,
      DefaultInput,
    },

    data() {
      return {
        input01: '',
        input02: '',
        input03: '',
        input04: '',
        input05: '',

        dataItems: this.items,
        // dataItemGiftPapers: this.itemGiftPapers,

        options: [],

        giftPapers: [],
      }
    },

    watch: {
      items(newVal) {
        this.dataItems = newVal
      }
    },

    mounted(){
      if(this._.find(this.dataItems, {type: 'select'})){
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          filterCountriesUSA(countries)
          this.options = countries;
        })
      }

      if(this._.find(this.dataItems, {type: 'radio'})){
        this.$store.dispatch('fetchPackagingGiftPapers', '?filter[enable]=1&page=0&limit=100000').then(response => {
          this.giftPapers = this.getRespPaginateData(response)
        })
      }
    },

    methods: {
      changeCountry(val, index) {
        this.dataItems[index].value = val
      },

      changeOrderFee(val, index) {
        this.dataItems[index].value = val
      },

      changeGiftPaper(val, index = false){
        this.dataItems[index].value = val
      }
    }
  }
</script>

<style scoped>

</style>
