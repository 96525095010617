<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_dimensions'])"></div>
        {{ $t('ordersInbound_dimensions.localization_value.value') }}
        <!--        Dimensions-->
      </div>
    </template>

    <template slot="body">
      <div class="fragment pt-4">
        <DimensionsBlock
            :ordersInbound="ordersInbound"
        />
      </div>
    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import DimensionsBlock from "../../../../components/chunks/DimensionsBlock/DimensionsBlock";

  export default {
    name: "Dimensions",
    components: {
      AccordBlock,
      DimensionsBlock,
    },

    props: {
      ordersInbound: Object
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
