<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_shipmentDetails', ])"></div>
      {{ $t('ordersInbound_shipmentDetails.localization_value.value') }}
      <!--      Shipment Details-->
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_shippingDate', ])"></div>
        <DatePickerDefault
            :label="$t('ordersInbound_shippingDate.localization_value.value')"
            :value="ordersInbound.data.shippingDate"
            v-bind:class="{'ui-no-valid': ordersInbound.validation.shippingDate}"
            :errorTxt="$t(`${ordersInbound.validationTranslate.shippingDate}.localization_value.value`)"
            :error="ordersInbound.validation.shippingDate"
        >
          <template slot="body">
            <date-picker
                v-model="ordersInbound.data.shippingDate"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :readonly="true"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_trackingNumber'])"></div>
        <DefaultInput
            :label="$t('ordersInbound_trackingNumber.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': ordersInbound.validation.trackingNumber}"
            :errorTxt="$t(`${ordersInbound.validationTranslate.trackingNumber}.localization_value.value`)"
            :error="ordersInbound.validation.trackingNumber"
            :disabled="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType"
            v-model="ordersInbound.data.trackingNumber"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-if="isAdmin && ($route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||
                 $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value)"

           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_condition'])"></div>
        <DefaultSelect
            :label="$t('ordersInbound_condition.localization_value.value')"
            :options="ordersInbound.data.condition"
            :optionsLabel="'name'"
            v-bind:class="{'ui-no-valid': ordersInbound.validation.conditionSelected}"
            :errorTxt="$t(`${ordersInbound.validationTranslate.conditionSelected}.localization_value.value`)"
            :error="ordersInbound.validation.conditionSelected"
            :selected="ordersInbound.data.conditionSelected"
            @change="(val) => ordersInbound.setConditionSelected(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from "vue2-datepicker";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
// import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "ShipmentDetailsBlock",
  components: {
    DefaultSelect,
    DatePickerDefault,
    DatePicker,
    DefaultInput,
    // DefaultSelect,
  },

  props: {
    ordersInbound: Object,
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  }
}
</script>

<style scoped>

</style>
