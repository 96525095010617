<template>
  <modal
      @close="$emit('close')"
      class="custom-popup large-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_products'])"></div>
        {{ $t(`ordersInbound_products.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <CreateProductsBlock
                :ordersInbound="ordersInbound"
                :user="user"
                :products="ordersInbound.data.AddedProducts"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_addProducts',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            :value="$t(`common_addProducts.localization_value.value`)"
            @click.native="addProduct"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {ordersInbound} from "../../../../../store/ordersInbound";
import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
import CreateProductsBlock from "../../components/components/chunks/CreateProductsBlock/CreateProductsBlock";
import {ordersInboundMixin} from "../../../../../mixins/ordersInbound/ordersInboundMixin";
import {ProductForCell} from "../../../../globalModels/ProductForCell";
// import SelectProductBlock from "../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "AddNewProductPopup",
  components: {
    CreateProductsBlock,
    Modal,
    MainButton,
    // SelectProductBlock,
    // DefaultInput
  },

  mixins: [
    ordersInbound,
    ordersInboundMixin,
    proformMixins
  ],

  props: {
    ordersInbound: Object,
    user: Object,
  },

  data() {
    return {
      localProductsForCellArray: []
    }
  },

  mounted() {
    // this.addNewLocalProductForCell()
  },

  methods: {
    addProduct(){
      if(!this.ordersInbound.productsValidation(false, true))
        return

      if(this.ordersInbound.data.AddedProducts.length > 0){
        this.productMassStore().then(() => {
          this.ordersInbound.getProcessResults().map((item, index) => {
            let localProductsForCell = this.addNewLocalProductForCell(item, index)
            localProductsForCell.setProductCount(localProductsForCell.data.ProformProduct.getProductItemsQuantity())

            this.ordersInbound.data.productsForCellArray.push(localProductsForCell)
          })

          let currentOrder = this.$store.getters.getOrdersInboundItem

          let freeCells = [...this.addOtherFreeCells(currentOrder)]

          let mixedFreeCells = [...this.addMixedFreeCells(currentOrder)]

          let userCells = [...this.addOtherUserCells(currentOrder, false, [])]

          let productForCell = this._.last(this.ordersInbound.data.productsForCellArray)

          productForCell.data.FreeCellArray = freeCells
          productForCell.data.MixedCellArray = mixedFreeCells
          productForCell.data.UserCellArray = userCells

          this.ordersInbound.data.AddedProducts = []
        })
      }

      this.$emit('close')
    },

    addNewLocalProductForCell(item, index){
      // let newProformModel = new ProformProduct()
      // this.initializeProductProformArray({product: newProformModel}, 'product')
      let newProformModel = this.ordersInbound.data.AddedProducts[index]
      newProformModel.product.productObject = item
      return new ProductForCell(false, newProformModel)
      // return productForCell
      // this.localProductsForCellArray.push(productForCell)
    }

  },
}
</script>

<style scoped>

</style>
