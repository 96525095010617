<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getOrdersInboundLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
      </div>

      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
      >
        <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
        <MainButton class="btn-fit-content ml-2"
                    v-if="navTabs.all.active || navTabs[ORDER_INBOUND_NAV.RETURNS.name].active"
                    :value="$t('common_AddNew.localization_value.value')"
                    :ico="true"
                    @click.native="changeShipmentCreatePopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
        <router-link v-else :to="getAddOrderLinkByType">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>

    <ShipmentCreatePopup
      v-if="isModalShipmentCreatePopup"
      :fbm="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBM.value"
      :fba="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBA.value"
      :fbmReturn="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBMReturn.value"
      :fbaReturn="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBAReturn.value"
      @close="changeShipmentCreatePopup(false)"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import ShipmentCreatePopup from "../../../../popups/ShipmentCreatePopup/ShipmentCreatePopup";
  import {ORDER_INBOUND_NAV, ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";


  export default {
    name: "OrdersInboundHead",
    components: {
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      PlusIconSVG,
      ShipmentCreatePopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    computed: {
      activeTabType() {
        return this._.find(this.navTabs, {active: true}).name
      },

      getAddOrderLinkByType() {
        let activeTab = this._.find(this.navTabs, {active : true})
        return this.$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE[activeTab.name].value
      },
    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        tab: this.activeTab,

        isModalShipmentCreatePopup: false,
        ORDER_INBOUND_NAV: ORDER_INBOUND_NAV,
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      }
    },

    methods: {
      changeShipmentCreatePopup(val){
        this.isModalShipmentCreatePopup = val
      }
    }
  }
</script>

<style scoped>

</style>

