<template>
  <DefaultCheckbox
          :value="checkboxValue"
          @input="(val) => $emit('changeProductChecked', val)"
  />
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "ProductCheckedBlock",
    components: {DefaultCheckbox},

    props: {
      item: Object,
    },

    data() {
      return {
        checkboxValue: false,
      }
    },

    computed: {
      scanned(){
        return this.item.data.scannedCount
      },
    },

    watch: {
      scanned(scannedCount){
        this.checkboxValue = scannedCount >= this.item.data.productCount
      }
    },

    mounted() {
      this.checkboxValue = this.item.data.scannedCount >= this.item.data.productCount
    }

  }
</script>

<style scoped>

</style>
