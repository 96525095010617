<template>
  <modal
      @close="$emit('close')"
      class="custom-popup large-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_products'])"></div>
        {{ $t(`ordersInbound_products.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <!--<CellBlock-->
            <!--:user="user"-->
            <!--:productItem="productDefault"-->
        <!--/>-->
        <ProductsBlock
                :ordersInbound="ordersInbound"
                :localProducts="true"
                :user="user"
                :products="localProductsForCellArray"
                :disabledWarehouse="true"
                @addLocalProduct="addLocalProduct"
                @removeLocalProduct="removeLocalProduct"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_addProducts',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            :value="$t(`common_addProducts.localization_value.value`)"
            @click.native="addProduct"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {ordersInbound} from "../../../../../store/ordersInbound";
import ProductsBlock from "../../components/components/chunks/ProductsBlock/ProductsBlock";
import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
import {ProformProduct} from "../../../../globalModels/ProformProduct";
import {ProductForCell} from "../../../../globalModels/ProductForCell";
// import SelectProductBlock from "../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "ProductsPopup",
  components: {
    ProductsBlock,
    Modal,
    MainButton,
    // SelectProductBlock,
    // DefaultInput
  },

  mixins: [
    ordersInbound,
    proformMixins
  ],

  props: {
    ordersInbound: Object,
    user: Object,
    productDefault: Array,
  },

  data() {
    return {
      ProformProducts: [new ProformProduct()],
      localProductsForCellArray: []
    }
  },

  mounted() {
    this.addNewLocalProductForCell()
  },

  methods: {
    addProduct(){
      let valid = true
      this.localProductsForCellArray.map((item) => {
        if(!item.data.ProformProduct.productProformValidation({
          productName: true,
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          proformMaterial: true,
        })) valid = false
      })

      if(!valid) return

      this.localProductsForCellArray.map((item) => {
        item.setProductCount(item.data.ProformProduct.getProductItemsQuantity())
        this.ordersInbound.data.productsForCellArray.push(item)
      })
      this.$emit('close')
    },

    addLocalProduct(){
      this.addNewLocalProductForCell()
    },

    removeLocalProduct(index){
      this.localProductsForCellArray.splice(index, 1)
    },

    addNewLocalProductForCell(){
      let newProformModel = new ProformProduct()
      this.initializeProductProformArray({product: newProformModel}, 'product')
      let productForCell = new ProductForCell(false, newProformModel)
      this.localProductsForCellArray.push(productForCell)
      console.log(2222);
      console.log(this.localProductsForCellArray);
    }
  },
}
</script>

<style scoped>

</style>
