<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_shipping'])"></div>
        {{ $t('ordersInbound_shipping.localization_value.value') }}
      </div>
    </template>

    <template slot="body">
      <div class="fragment pt-4">

<!--        <TypeReturnBlock-->
<!--            v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value"-->
<!--            :ordersInbound="ordersInbound"-->
<!--            :editAdmin="true"-->
<!--        />-->
<!--        <ShippingCompanyBlock-->
<!--            :ordersInbound="ordersInbound"-->
<!--        />-->
        <ShipmentDetailsBlock
            :ordersInbound="ordersInbound"
        />
        <DocumentsBlock
            v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||
              $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"
            :ordersInbound="ordersInbound"
            :documents="ordersInbound.data.Documents"
        />
            <!--v-if="$route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"-->
<!--        <CommentsBlock-->
<!--            :ordersInbound="ordersInbound"-->
<!--        />-->

<!--        <div class="order-create__row custom-row"-->
<!--             v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||-->
<!--              $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"-->
<!--        >-->
<!--          <div class="order-create__col custom-col"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['ordersInbound_checkAndRepack'])"></div>-->
<!--            <DefaultCheckbox-->
<!--                class="wfc"-->
<!--                :label="$t('ordersInbound_checkAndRepack.localization_value.value')"-->
<!--                :value="ordersInbound.data.checkAndRepack"-->
<!--                @change.native="ordersInbound.data.checkAndRepack = !ordersInbound.data.checkAndRepack"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
import {ORDER_INBOUND_TYPE} from "../../../../../../../../staticData/staticVariables";
// import TypeReturnBlock from "../../../../components/chunks/TypeReturnBlock/TypeReturnBlock";
// import ShippingCompanyBlock from "../../../../components/chunks/ShippingCompanyBlock/ShippingCompanyBlock";
import ShipmentDetailsBlock from "../../../../components/chunks/ShipmentDetailsBlock/ShipmentDetailsBlock";
import DocumentsBlock from "../../../../components/chunks/DocumentsBlock/DocumentsBlock";
// import CommentsBlock from "../../../../components/chunks/CommentsBlock/CommentsBlock";
// import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "Shipping",
  components: {
    AccordBlock,
    // TypeReturnBlock,
    // ShippingCompanyBlock,
    ShipmentDetailsBlock,
    DocumentsBlock,
    // CommentsBlock,
    // DefaultCheckbox,
  },

  mixins: [

  ],

  props: {
    ordersInbound: Object,
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">

</style>
