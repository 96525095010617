<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_AddProductFromExisting'])"></div>
      {{$t('ordersInbound_AddProductFromExisting.localization_value.value')}}
    </div>

    <div class="custom-row">
      <div class="custom-col">
        <div class="d-flex">
          <RadioDefault
              class="white-space-line width-initial mr-3"
              v-for="(item, index) in warehouses"
              :key="index"
              :label="item.name"
              :name="'warehouse'"
              :value="ordersInbound.data.Order.data.warehouse && item.id === ordersInbound.data.Order.data.warehouse.id"
              @input="changeWarehouse(item)"
              :disabled="disabledWarehouse || item.id === CAMBRIDGE_WAREHOUSE.storage_id"
          />
        </div>
      </div>
    </div>


    <div class="mb-2 color-orange-validation" v-if="ordersInbound.validation.noProducts">
      {{ $t(`${ordersInbound.validationTranslate.noProducts}.localization_value.value`) }}
    </div>

    <div class="fragment" v-bind:class="{'loading-products' : productLoading}">
      <template v-for="(productItem, index) in products">
        <div class="order-create__title-horizontal"
             v-if="item = getProductByLocalType(productItem)"
             :key="index + '1'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
          <TitleHorizontal
              :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
              :rightBtn="true"
              :rightBtnType="'delete'"
              :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
              @rightBtnClick="removeProformProductByLocalType(products, index)"
          />
          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>

              <DefaultSelect
                  class="w-100"
                  :options="optionsProducts"
                  :optionsLabel="'name_for_fba'"
                  :otherValue="'product'"
                  :label="$t('product_ProductName.localization_value.value')"
                  v-bind:class="{'ui-no-valid': item.validation.productName}"
                  :errorTxt="serverError ? item.validationTxt.productName : $t(`${item.validationTranslate.productName}.localization_value.value`)"
                  :error="item.validation.productName"
                  :selected="item.product.productObject"
                  @change="(val) => changeProductObjectByLocal(item, val, productItem)"
              />
            </div>
          </div>
        </div>

        <ProductItemCreation
            v-if="item.product.productName"
            :key="index"
            :product="item"
            :serverError="serverError"
            :categoryCaption="false"
            :hideAddProfile="true"
            :hideChangeProformProduct="false"
            :altVersion="true"
            @changeCategory="({val, nameVal}) => changeCategoryProformByLocalType({val, nameVal, item: products[index]})"
            @changeProformSelectItem="changeProformSelectItemByLocalType(products[index])"
        />
        <!--@changeSomethingField="changeSomethingField(item)"-->
      </template>
    </div>

    <div class="order-create__product-btn add-product-btn-wrap"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>

      <span v-if="!localProducts"
            class="order-create__product-btn-i add-product-btn site-link"
            @click="addProformProduct(ordersInbound.data.ProformProducts)">
          + {{$t('common_AddProduct.localization_value.value')}}
        </span>

      <span v-else
            class="order-create__product-btn-i add-product-btn site-link"
            @click="$emit('addLocalProduct')">
          + {{$t('common_AddProduct.localization_value.value')}}
        </span>
    </div>

<!--    <div class="order-create__total mb-3"-->
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['common_TotalShippingCost',])"></div>-->
<!--      {{$t('common_TotalShippingCost.localization_value.value')}}-->
<!--      <span>${{calculatePrice}}</span>-->
<!--    </div>-->

  </div>
</template>

<script>
import {ordersInboundMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
import {CAMBRIDGE_WAREHOUSE, ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {proformMixins} from "../../../../../../../mixins/proformMixins/proformMixins";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
// import SelectProductBlock from "../../../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "ProductsBlock",
  components: {
    RadioDefault,
    TitleHorizontal,
    ProductItemCreation,
    // SelectProductBlock,
    DefaultSelect,
  },

  mixins:[
    ordersInboundMixin,
    proformMixins,
  ],

  watch: {
    getUserId: function (id) {
      this.getProductsByUserId(id)
    },

  },


  props:{
    ordersInbound: Object,
    user: Object,
    products: Array,
    localProducts: {
      type: Boolean,
      default: false
    },
    disabledWarehouse: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      ordersInboundType: ORDER_INBOUND_TYPE,
      optionsProducts: [],
      serverError: false,

      warehouses: [],
      productLoading: false,

      CAMBRIDGE_WAREHOUSE: CAMBRIDGE_WAREHOUSE,
    }
  },

  mounted() {

    this.$store.dispatch('fetchWarehouseStorage').then((response) => {
      this.warehouses = this.getRespPaginateData(response)

      if(!this.ordersInbound.data.Order.data.warehouse) {
        this.ordersInbound.data.Order.setWarehouse(this._.first(this.warehouses))
      }

      if (this.$route.query.strgId) {
        let currentStorage = this.warehouses.find(item => {
          return +item.id === +this.$route.query.strgId
        })
        this.ordersInbound.data.Order.setWarehouse(currentStorage)
      }

      this.getProductsByUserId(this.user.getUserId())
    })
  },

  methods: {

    changeWarehouse(val) {
      this.ordersInbound.data.Order.setWarehouse(val)
      this.getProductsByUserId(this.user.getUserId()).then(() => {
        this.products.map((item, index) => {
          let product = this._.find(this.optionsProducts, {id: item.product.productObject?.id})
          if(product) {
            this.products[index].setProductObject(product)
          }
        })
      })
    },

    async getProductsByUserId(id) {
      this.productLoading = true
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      if (this.isAdmin && !id) return

      if(this.isAdmin){
        if(!this.localProducts) {
          myQuery.where('ProductUserId', id)
        }
      }

      myQuery.where('ProductInDraft', '0')

      myQuery.where('byStorageId', this.ordersInbound.data.Order.data.warehouse?.id)

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch
      if(this.localProducts) {
        typeFetch = 'fetchProductsWithCells'
        url = url + `&user_id=${id}`
      } else {
        typeFetch = 'fetchProducts'
      }

      return this.$store.dispatch(typeFetch, url).then((response) => {
        if(this.localProducts) {
          this.optionsProducts = this.getRespData(response)
        } else {
          this.optionsProducts = this.getRespPaginateData(response)
        }
        this.productLoading = false
      })
    },

    getProductByLocalType(productItem){
      if(this.localProducts){
        return productItem.data.ProformProduct
      }

      return productItem
    },

    changeCategoryProformByLocalType({val, nameVal, item}){
      let product = item
      if(this.localProducts){
        product = item.data.ProformProduct
      }

      this.changeCategoryProform({val, nameVal, item: product}, null, true)
    },

    changeProformSelectItemByLocalType(item) {
      let product = item
      if(this.localProducts){
        product = item.data.ProformProduct
      }

      this.changeProformSelectItem(product)
    },

    removeProformProductByLocalType(products, index){
      if(this.localProducts){
        this.$emit('removeLocalProduct', index)
      } else {
        this.removeProformProduct(products, index)
      }
    },

    changeProductObjectByLocal(item, val, productForCell) {
      if(this.localProducts){
        this.changeProductObject(item, val, productForCell)
      } else {
        this.changeProductObject(item, val)
      }
    }
  }
}
</script>

<style scoped>
.width-initial{
  width: initial;
}

.loading-products{
  opacity: 0.5;
  pointer-events: none;
}
</style>
