<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_scanner'])"></div>
        {{ $t('ordersInbound_scanner.localization_value.value') }}
      </div>
    </template>

    <template slot="body">
      <div class="fragment pt-4"
           v-bind:class="{'disabled-btn' : $store.getters.getOrdersInboundBtn}"
      >
<!--        <div class="section-label noPrintable"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['ordersInbound_scanner'])"></div>-->
<!--          {{ $t('ordersInbound_scanner.localization_value.value') }}-->
<!--        </div>-->

        <div class="custom-row noPrintable">
          <div class="custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fba_scannerHere'])"></div>
            <div class="section-label">
              <DefaultInput
                  :label="$t('fba_scannerHere.localization_value.value')"
                  :type="'text'"
                  v-model="scan"
                  @change="changeScanner"
              />

            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col">
              <div class="d-flex align-center" style="height: 42px;">
                <template v-for="(item, index) in warehouses">
                  <RadioDefault
                      class="white-space-line width-initial mr-3"
                      v-if="ordersInbound.data.Order.data.warehouse && item.id === ordersInbound.data.Order.data.warehouse.id"
                      :key="index"
                      :label="item.name"
                      :name="'warehouse'"
                      :value="ordersInbound.data.Order.data.warehouse && item.id === ordersInbound.data.Order.data.warehouse.id"
                      :disabled="true"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <ProductsAdminBlock
            :ordersInbound="ordersInbound"
            :user="ordersInbound.data.User"
            :proformProducts="ordersInbound.data.ProformProducts"
            :productsForCellArray="ordersInbound.data.productsForCellArray"
            :addedProducts="ordersInbound.data.AddedProducts"
        />



<!--        <ProductsBlock-->
<!--            class="mb-5"-->
<!--            :ordersInbound="ordersInbound"-->
<!--            :user="user"-->
<!--            :products="ordersInbound.data.ProformProducts"-->
<!--        />-->

<!--        <CreateProductsBlock-->
<!--            :ordersInbound="ordersInbound"-->
<!--            :user="user"-->
<!--            :products="ordersInbound.data.AddedProducts"-->
<!--        />-->
      </div>
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
import ProductsAdminBlock from "../ProductsAdminBlock/ProductsAdminBlock";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import {addProductForCellMixin} from "@/mixins/productMixins/addProductForCellMixin";
import {ordersInboundProductMixin} from "@/mixins/ordersInbound/ordersInboundProductMixin";
// import ProductsBlock from "../../../../components/chunks/ProductsBlock/ProductsBlock";
// import CreateProductsBlock from "../../../../components/chunks/CreateProductsBlock/CreateProductsBlock";

export default {
  name: "Products",
  components: {
    RadioDefault,
    AccordBlock,
    DefaultInput,
    ProductsAdminBlock,
    // ProductsBlock,
    // CreateProductsBlock
  },

  mixins: [addProductForCellMixin, ordersInboundProductMixin],

  props: {
    ordersInbound: Object,
  },

  data() {
    return {
      scan: '',
      warehouses: [],
    }
  },

  mounted() {
    this.$store.dispatch('fetchWarehouseStorage').then((response) => {
      this.warehouses = this.getRespPaginateData(response)
    })
  },

  methods: {
    async changeScanner(val){
      let added = false
      let foundIndex = null

      val = val.replaceAll(' ', '')

      if(val.length > 0){
        this.ordersInbound.data.productsForCellArray.map((item, index) => {
          if(added) return //|| item.data.scannedCount === item.data.productCount
          if(item.data.ProformProduct.product.productObject.upc_code === val){
            item.addScannedProductInbound()
            added = true
            foundIndex = index
          }
        })
      }

      if(!added) {
        this.getProductFromWarehouse(this.ordersInbound.data.User, val, this.ordersInbound.data.Order.getWarehouse()?.id).then(data => {
          let {product, localProductsForCellArray} = data
          let localProductForCell = this._.first(localProductsForCellArray)

          if(product) {
            this.changeProductObject(localProductForCell.data.ProformProduct, product, localProductForCell, true).then(() => {
              setTimeout( () => {
                let checkAdded = this.addProductToArray(localProductForCell, this.ordersInbound.data.productsForCellArray)

                if(!checkAdded) return

                let addedProduct = this._.last(this.ordersInbound.data.productsForCellArray)
                addedProduct.addScannedProductInbound()
                this.openNotify('success', 'common_notificationRecordChanged')

                let lastIndex = this.ordersInbound.data.productsForCellArray.length - 1
                this.upScannedProductInArray(lastIndex)

              }, 1)
            })
          }
        })
      }

      if(foundIndex !== null) {
        this.upScannedProductInArray(foundIndex)
      }

      //check for '-' so we don't run this code if it's not a cell name
      if (val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0) {
        let idValue = val.split('-')[1]
        idValue = parseInt(idValue)

        let firstProduct = this._.first(this.ordersInbound.data.productsForCellArray)
        firstProduct.addScannedProductToCell(idValue)

        // this.ordersInbound.data.productsForCellArray.forEach(item => {
        //   item.addScannedProductToCell(idValue)
        // })
      }

      this.scan = ''
    },

    upScannedProductInArray(foundIndex) {
      let copyProductForCell = this._.clone(this.ordersInbound.data.productsForCellArray[foundIndex])
      this.ordersInbound.data.productsForCellArray.splice(foundIndex, 1)
      this.ordersInbound.data.productsForCellArray.unshift(copyProductForCell)

      this.changeInboundProductInCells()
    }

  }
}
</script>

<style scoped lang="scss">

</style>
