import { render, staticRenderFns } from "./ProductCheckedBlock.vue?vue&type=template&id=063e62d3&scoped=true"
import script from "./ProductCheckedBlock.vue?vue&type=script&lang=js"
export * from "./ProductCheckedBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063e62d3",
  null
  
)

export default component.exports